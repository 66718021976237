import React, { useEffect } from 'react';
import { FaCalendarCheck, FaUserGroup } from 'react-icons/fa6';
import { MdOutlineAccessTimeFilled } from 'react-icons/md';
import { FaBookmark } from 'react-icons/fa';
import moment from 'moment';

const BookingCard = ({ firstName, lastName, bookingDate, partySize }) => {
  const formatDate = (dateString) => {
    const date = moment(dateString);
    return date.format('ddd, D MMM YYYY'); // Short form of day
  };

  const formatTime = (dateString) => {
    const date = moment(dateString);
    return date.format('h:mm a'); // Time in 12-hour format
  };

  return (
    <div>
      {firstName && lastName && (
        <div className="detail-item">
          <FaBookmark />
          <span className="wabi-subtitle">
            {firstName} {lastName}
          </span>
        </div>
      )}
      <div className="detail-item">
        <FaUserGroup className="icon" />
        <span className="wabi-subtitle">{partySize} Guests</span>
      </div>
      <div className="detail-item">
        <FaCalendarCheck className="icon" />
        <span className="wabi-subtitle">{formatDate(bookingDate)}</span>
      </div>
      <div className="detail-item">
        <MdOutlineAccessTimeFilled className="icon" />
        <span className="wabi-subtitle">{formatTime(bookingDate)}</span>
      </div>
    </div>
  );
};

export default BookingCard;
