import React, { Component, useState, useEffect } from 'react';
import './step1.css';
import { URL } from './../config';
import axios from 'axios';
import { isMobile, isBrowser, isTablet } from 'react-device-detect';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Grid, Paper, Modal, Backdrop, Fade } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Loader } from 'react-loader-spinner';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import DayPicker from 'react-day-picker/DayPicker';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate, addDays } from 'react-day-picker/moment';
import moment from 'moment';
import BounceLoader from 'react-spinners/BounceLoader';
import { css } from '@emotion/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row, Col, Spinner } from 'react-bootstrap';
import logo from './../assets/images/logo.png';
// import { useMediaQuery } from 'react-responsive';
import { ButtonBase } from '@material-ui/core';
import { VIEWSTATE, RECURRINGTYPE, RECURRINGVALUE } from './../common/enums/enum.js';
import closeBtn from './../assets/images/close.svg';
import logoImg from './../assets/images/logo-white.png';
import {
  getPositionLeft,
  getPositionTop,
  getRadHeight,
  getRadWidth,
  getTableHeight,
  getTableImg,
  getTableWidth,
  radToDeg,
  tableNamePositionLeft,
  tableNamePositionLeftTranslate,
  tableNamePositionMarginLeft,
  tableNamePositionMarginTop
} from '../common/utility';

// const useStyles = makeStyles((theme) => ({

// }));

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: 35
  },
  formControl: {
    // minWidth: 120,
    maxWidth: 169,
    flexGrow: 1
  },
  experienceContainer: {
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
    padding: '10px 10px 25px 10px',
    borderRadius: 10,
    width: 765,
    maxWidth: '100%',
    margin: '100px auto',
    opacity: 0.8
  },
  experienceContainerHeader: {
    width: '50%',
    textAlign: 'right',
    paddingRight: 25,
    height: 24
  },
  experienceItem: {
    width: 200,
    height: 200,
    padding: 13,
    textAlign: 'left',
    color: '#fff',
    fontWeight: 500,
    cursor: 'pointer',
    userSelect: 'none'
  },
  expDesc: {
    fontSize: 12,
    marginTop: 8,
    maxHeight: '4.4em'
  },
  gridContainer: {
    marginTop: 5
  },
  right: {
    textAlign: 'right'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    width: '100%',
    height: '100%',
    backdropFilter: 'blur(10px)',
    outline: 'none'
  }
});

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

// const [open, setOpen] = useState(false);

class Step1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openSectionFloorModal: true,
      openSeatSelection: false,
      openCalendar: false,
      key: this.props.match.params.key,
      userId: 0,
      futureTime: this.getDefaultFutureTime(),
      availableTimeSlot: [],
      futureTimeSlots: null,
      allFloors: [],
      selectedFloors: [],
      title: document.getElementById('bookingWidgetRoot').getAttribute('data-title'),
      partySize: 2,
      date: new Date(),
      time: '',
      bookingTime: '',
      floor_id: 0,
      floor_name: '',
      shiftId: 0,
      futureTimeToday: [],
      partySizes: [],
      timeLabel: '',
      timeSlots: [],
      displayNoResultFound: false,
      displaySearchResult: false,
      displayHasSpecialDay: false,
      displayHasNoShift: false,
      loading: false,
      floors: [],
      shifts: [],
      experiences: [],
      specialDays: [],
      statusCode: 0,
      experience_id: null,
      experience_name: '',
      view_state: VIEWSTATE.LOADING,
      openDialog: false,
      dialogTitle: '',
      experienceStartDate: new Date(),
      bought_ticket: 0,
      experience_price: 0,
      showAlternate: false,
      allowGuestSelectSeat: false,
      allowGuestSelectSection: false,
      seatLoading: false,
      seatFloors: [],
      selectedSeatFloor: null,
      selectedSeatFloorTable: [],
      disabledConfirmButton: true,
      widgetMessageTitle: '',
      widgetMessage: '',
      onlinePaymentFee: null,
      currentTime: null
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.experienceState = this.experienceState.bind(this);
    this.bookingState = this.bookingState.bind(this);
    this.selecExperience = this.selecExperience.bind(this);

    this.goBackToOption = this.goBackToOption.bind(this);
    this.disableDays = this.disableDays.bind(this);
    this.displayAvailability = this.displayAvailability.bind(this);
    this.daySuffix = this.daySuffix.bind(this);
    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onDaySelected = this.onDaySelected.bind(this);
    this.isSelectedSeatiSTooMuch = this.isSelectedSeatiSTooMuch.bind(this);
    // this.openCloseCalendar = this.openCloseCalendar.bind(this);
    // const rounding = 30 * 60 * 1000; /*ms*/
    // start = moment();
    // start = moment(Math.ceil((+start) / rounding) * ROUNDING);
    // console.log(start.format("D YYYY, h:mm:ss a"));
  }

  componentWillMount() {
    this.getBusinessProfile();
    this.getDateTimeslots(this.state.date);
  }

  handleClickOpen = (title) => {
    // setOpen(true);
    this.setState({
      openDialog: true,
      dialogTitle: title
    });
  };

  handleClose = () => {
    // setOpen(false);
    this.setState({
      openDialog: false
    });
  };

  getBusinessProfile = () => {
    axios({
      method: 'GET',
      url: `${URL}/widget/me`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      }
    }).then((response) => {
      let responseData = response.data.data;
      let user = responseData.user;
      let businessProfile = user.business_profile ? user.business_profile.business_name : '';
      this.setState({
        partySize: responseData.partySizes[0],
        userId: user.id,
        title: businessProfile,
        futureTime: responseData.futureTime,
        futureTimeToday: responseData.futureTime,
        //        time: responseData.futureTime[0].value,
        partySizes: responseData.partySizes,
        timeLabel: responseData.futureTime[0].label,
        allowGuestSelectSeat: Boolean(responseData.user.setting.allow_guest_select_seat),
        allowGuestSelectSection: Boolean(responseData.user.setting.allow_guest_select_section),
        widgetMessageTitle: responseData.user.setting.widget_note_title,
        widgetMessage: responseData.user.setting.widget_note_message,
        onlinePaymentFee: responseData.user.setting.online_booking_payment,
        date: new Date(responseData.currentTime)
      });

      axios({
        method: 'GET',
        url: `${URL}/widget/experience/list`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.key}`
        },
        data: {}
      })
        .then((res) => {
          let responseData = res.data.data;

          this.setState({
            experiences: responseData.experiences
          });

          if (this.state.experiences.length === 0) {
            this.setState({
              view_state: VIEWSTATE.TABLE_BOOKING
            });
          } else {
            this.setState({
              view_state: VIEWSTATE.INITIAL
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({
            view_state: VIEWSTATE.TABLE_BOOKING
          });
        });
    });
  };

  handleDisplayFloors = (event) => {
    var thisLabel = event.currentTarget.getAttribute('data-label');
    var shift_id = parseInt(event.currentTarget.getAttribute('data-shiftid'));
    const selectedDate = event.currentTarget.getAttribute('data-date');
    const floors =
      this.state.availableTimeSlot.length > 0
        ? this.state.availableTimeSlot.find((x) => x.shift_id === shift_id).floors
        : this.state.futureTimeSlots[selectedDate].find((x) => x.shift_id === shift_id).floors;
    const bookingTime = event.currentTarget.value;

    if (!this.state.allowGuestSelectSeat) {
      this.setState({
        bookingTime: bookingTime,
        selectedFloors: floors,
        shiftId: shift_id,
        timeLabel: thisLabel,
        openSectionFloorModal: true,
        openSeatSelection: true,
        date: selectedDate ? selectedDate : this.state.date
      });
    } else {
      this.setState({
        seatLoading: true
      });
      axios({
        method: 'GET',
        url: `${URL}/widget/floor_tables?date=${formatDate(this.state.date, 'YYYY-MM-DD')}&time=${
          event.currentTarget.value
        }&partySize=${this.state.partySize}${
          this.state.experience_id ? '&experience_id=' + this.state.experience_id : ''
        }`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.key}`
        }
      })
        .then((result) => {
          let responseData = result.data.data;
          this.setState({
            seatLoading: false,
            bookingTime: bookingTime,
            selectedFloors: floors,
            shiftId: shift_id,
            timeLabel: thisLabel,
            openSectionFloorModal: true,
            openSeatSelection: true,
            date: selectedDate ? selectedDate : this.state.date,
            seatFloors: responseData.floors,
            selectedSeatFloor: responseData.floors.length > 0 ? responseData.floors[0] : null
          });
        })
        .catch((error) => {
          if (error.response) {
            let message = error.response.data.message;
            this.handleClickOpen(message);
          } else {
            this.handleClickOpen(error);
          }

          this.setState({
            seatLoading: false
          });
        });
    }
  };

  handleChangeTime = (event) => {
    this.setState({
      time: event.target.value,
      timeLabel: event.currentTarget.getAttribute('data-label')
    });
  };

  handlePartySize = (event) => {
    const name = event.target.name;
    this.setState({
      [name]: event.target.value,
      timeLabel: '',
      bookingTime: '',
      availableTimeSlot: []
    });
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    const input = dayPickerInput.getInput();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const selectedDate = new Date(selectedDay.getTime());
    selectedDate.setHours(0, 0, 0, 0);
    this.setState({
      date: selectedDay
    });
    this.getDateTimeslots(selectedDate, this.state.experience_id);
    //    if (this.state.experience_id == null) {
    //      this.setState({
    //        date: selectedDay,
    //        futureTime:
    //          selectedDate.getTime() === today.getTime()
    //            ? this.state.futureTimeToday
    //            : this.getDefaultFutureTime()
    //      });
    //    } else {
    //      this.setState({
    //        data: selectedDay
    //      });
    //    }
  };

  async handleSubmit(event) {
    event.preventDefault();
    if (this.state.loading === false) {
      const data = {
        userId: this.state.userId,
        partySize: this.state.partySize,
        date: formatDate(this.state.date, 'YYYY-MM-DD'),
        time: this.state.time
      };
      this.loadAvailableTime(
        this.state.partySize,
        this.state.date,
        this.state.time,
        this.state.experience_id
      );
    }
  }

  loadAvailableTime = (partySize, $date, time, experience_id) => {
    this.setState({
      loading: true,
      displayNoResultFound: false,
      displayHasNoShift: false,
      displayHasSpecialDay: false,
      displaySearchResult: false,
      showAlternate: false
    });

    axios({
      method: 'GET',
      url: `${URL}/widget/tables?date=${formatDate(
        $date,
        'YYYY-MM-DD'
      )}&time=${time}&partySize=${partySize}${
        experience_id ? '&experience_id=' + experience_id : ''
      }`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      }
    })
      .then((response) => {
        let responseData = response.data.data;
        if (
          (Array.isArray(responseData.availableTimeSlot) &&
            responseData.availableTimeSlot.length) ||
          responseData.futureTimeSlot
        ) {
          this.setState({
            availableTimeSlot: responseData.availableTimeSlot,
            futureTimeSlots: responseData.futureTimeSlot,
            allFloors: responseData.allFloors,
            loading: false,
            selectedFloors: [],
            bookingTime: '',
            displaySearchResult: true,
            showAlternate: responseData.availableTimeSlot.length > 0 ? false : true,
            displayNoResultFound: responseData.availableTimeSlot.length > 0 ? false : true
          });

          return;
        }

        if (responseData.hasSpecialDay) {
          this.setState({
            displayNoResultFound: true,
            displayHasSpecialDay: true,
            loading: false,
            availableTimeSlot: []
          });
          return;
        }

        if (responseData.noShiftAvailable) {
          this.setState({
            displayNoResultFound: true,
            displayHasNoShift: true,
            loading: false,
            availableTimeSlot: []
          });
          return;
        }

        this.setState({
          displayNoResultFound: true,
          availableTimeSlot: responseData.availableTimeSlot,
          futureTimeSlots: responseData.futureTimeSlot,
          loading: false
        });
      })
      .catch((error) => {
        this.setState({
          displayNoResultFound: true,
          displayHasNoShift: true,
          loading: false,
          availableTimeSlot: null
        });
      });
  };

  displayTablesFromFloor(id) {
    const selectedFloor = this.state.seatFloors.filter((i) => i.id == id)[0];
    this.setState({
      selectedSeatFloor: selectedFloor == undefined ? null : selectedFloor,
      selectedSeatFloorTable: [],
      disabledConfirmButton: true
    });

    // Display all floors here
    if (selectedFloor != undefined) {
      selectedFloor.tables.forEach((i) => {
        console.log('Table Name: ', i.name);
      });
    }
  }

  selectedTable(table) {
    const filteredTable = this.state.selectedSeatFloorTable.filter((i) => i.id === table.id);

    if (filteredTable.length > 0) {
      const removedTable = this.state.selectedSeatFloorTable.filter((i) => i.id !== table.id);
      this.setState({
        selectedSeatFloorTable: removedTable
      });
    } else {
      // this.setState({
      //   selectedSeatFloorTable: [...this.state.selectedSeatFloorTable, table]
      // })
      this.setState({
        selectedSeatFloorTable: [table]
      });
    }

    setTimeout(() => {
      const partySize = this.state.partySize;
      const totalSelectedTableSeats =
        this.state.selectedSeatFloorTable.length === 0
          ? 0
          : this.state.selectedSeatFloorTable.map((i) => i.capacity_max).reduce((a, b) => a + b);

      this.isSelectedSeatiSTooMuch(totalSelectedTableSeats, partySize);
    }, 300);
  }

  getDateTimeslots = ($date, experienceId) => {
    axios({
      method: 'GET',
      url:
        experienceId > 0
          ? `${URL}/widget/timeslots?date=${formatDate($date, 'YYYY-MM-DD')}&expId=${experienceId}`
          : `${URL}/widget/timeslots?date=${formatDate($date, 'YYYY-MM-DD')}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.state.key}`
      }
    })
      .then((response) => {
        if (response.data.data.availableTimeSlots.length > 0) {
          if (this.state.timeSlots.length == 0) {
            this.setState({
              timeSlots: response.data.data.availableTimeSlots
            });
          } else {
            this.setState({
              timeSlots: response.data.data.availableTimeSlots,
              time: response.data.data.availableTimeSlots[0].value
            });
          }
        } else {
          this.setState({
            timeSlots: response.data.data.availableTimeSlots,
            time: ''
          });
        }

        if (this.state.time === '') {
          var with_selected = false;
          var date = new Date();
          var hour = date.getHours() * 3600;
          this.state.timeSlots.map(({ value }, key) => {
            if (with_selected === false) {
              if (value > hour) {
                with_selected = true;
                this.setState({
                  time: value
                });
              } else if (this.state.timeSlots.length === key + 1) {
                with_selected = true;
                this.setState({
                  time: value
                });
              }
            }
          });
        }
      })
      .catch((error) => {
        this.setState({
          displayNoResultFound: true,
          displayHasNoShift: true,
          loading: false,
          availableTimeSlot: null
        });
      });
  };

  getDefaultFutureTime = () => {
    return [
      {
        value: 0,
        label: '12:00 am'
      },
      {
        value: 1800,
        label: '12:30 am'
      },
      {
        value: 3600,
        label: '01:00 am'
      },
      {
        value: 5400,
        label: '01:30 am'
      },
      {
        value: 7200,
        label: '02:00 am'
      },
      {
        value: 9000,
        label: '02:30 am'
      },
      {
        value: 10800,
        label: '03:00 am'
      },
      {
        value: 12600,
        label: '03:30 am'
      },
      {
        value: 14400,
        label: '04:00 am'
      },
      {
        value: 16200,
        label: '04:30 am'
      },
      {
        value: 18000,
        label: '05:00 am'
      },
      {
        value: 19800,
        label: '05:30 am'
      },
      {
        value: 21600,
        label: '06:00 am'
      },
      {
        value: 23400,
        label: '06:30 am'
      },
      {
        value: 25200,
        label: '07:00 am'
      },
      {
        value: 27000,
        label: '07:30 am'
      },
      {
        value: 28800,
        label: '08:00 am'
      },
      {
        value: 30600,
        label: '08:30 am'
      },
      {
        value: 32400,
        label: '09:00 am'
      },
      {
        value: 34200,
        label: '09:30 am'
      },
      {
        value: 36000,
        label: '10:00 am'
      },
      {
        value: 37800,
        label: '10:30 am'
      },
      {
        value: 39600,
        label: '11:00 am'
      },
      {
        value: 41400,
        label: '11:30 am'
      },
      {
        value: 43200,
        label: '12:00 pm'
      },
      {
        value: 45000,
        label: '12:30 pm'
      },
      {
        value: 46800,
        label: '01:00 pm'
      },
      {
        value: 48600,
        label: '01:30 pm'
      },
      {
        value: 50400,
        label: '02:00 pm'
      },
      {
        value: 52200,
        label: '02:30 pm'
      },
      {
        value: 54000,
        label: '03:00 pm'
      },
      {
        value: 55800,
        label: '03:30 pm'
      },
      {
        value: 57600,
        label: '04:00 pm'
      },
      {
        value: 59400,
        label: '04:30 pm'
      },
      {
        value: 61200,
        label: '05:00 pm'
      },
      {
        value: 63000,
        label: '05:30 pm'
      },
      {
        value: 64800,
        label: '06:00 pm'
      },
      {
        value: 66600,
        label: '06:30 pm'
      },
      {
        value: 68400,
        label: '07:00 pm'
      },
      {
        value: 70200,
        label: '07:30 pm'
      },
      {
        value: 72000,
        label: '08:00 pm'
      },
      {
        value: 73800,
        label: '08:30 pm'
      },
      {
        value: 75600,
        label: '09:00 pm'
      },
      {
        value: 77400,
        label: '09:30 pm'
      },
      {
        value: 79200,
        label: '10:00 pm'
      },
      {
        value: 81000,
        label: '10:30 pm'
      },
      {
        value: 82800,
        label: '11:00 pm'
      },
      {
        value: 84600,
        label: '11:30 pm'
      }
    ];
  };

  isSelectedSeatiSTooMuch = (totalSelectedTableSeats, partySize) => {
    if (totalSelectedTableSeats == 0) {
      this.setState({
        disabledConfirmButton: true
      });
      return;
    }

    const difference = totalSelectedTableSeats - partySize;
    if (difference < 0) {
      this.setState({
        disabledConfirmButton: true
      });
      return;
    }

    if (difference <= 3) {
      this.setState({
        disabledConfirmButton: false
      });
      return;
    }

    this.setState({
      disabledConfirmButton: true
    });
  };

  displayAvailableFloorsBtns = (allFloors, selectedFloors) => {
    let availableFloors = [];
    const { classes } = this.props;
    const allowGuestSelectSeat = this.state.allowGuestSelectSeat;
    const seatFloors = this.state.seatFloors;
    const selectedSeatFloor = this.state.selectedSeatFloor;
    const selectedSeatFloorTable = this.state.selectedSeatFloorTable;

    const disabledConfirmButton = this.state.disabledConfirmButton;

    if (selectedFloors.length > 0) {
      allFloors.forEach((obj) => {
        const idx = selectedFloors.findIndex((x) => x.id === obj.id);
        let flr = {
          floor_id: obj.id,
          floor_name: obj.floor_name,
          isActive: idx !== -1 ? true : false
        };

        availableFloors.push(flr);
      });
    }

    return !allowGuestSelectSeat ? (
      isMobile ? (
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={this.state.openSectionFloorModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={this.state.openSectionFloorModal}>
            <div
              className={classes.paper}
              style={{
                position: 'relative'
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  margin: '0 auto'
                }}
              >
                <div
                  style={{
                    width: 316,
                    height: 490,
                    background: '#000000bf',
                    borderRadius: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <img
                    src={logoImg}
                    className="d-inline"
                    style={{
                      position: 'absolute',
                      top: 14,
                      left: 16,
                      width: 75,
                      height: 21
                    }}
                    alt="checker"
                  />
                  <img
                    src={closeBtn}
                    className="d-inline"
                    style={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      width: 15,
                      height: 15,
                      filter: 'invert(1)',
                      cursor: 'pointer'
                    }}
                    onClick={(e) =>
                      this.setState({
                        openSectionFloorModal: false
                      })
                    }
                    alt="checker"
                  />

                  {/* Main Content */}
                  <div style={{ width: '100%' }}>
                    <h5
                      style={{
                        color: 'white',
                        marginTop: 60,
                        marginLeft: 17
                      }}
                    >
                      Select a section
                    </h5>
                    <div
                      style={{
                        height: 97,
                        margin: '15px 17px 0 17px',
                        borderRadius: 10,
                        background:
                          'linear-gradient(90deg, rgba(250,75,132,1) 57%, rgba(245,175,151,1) 100%)'
                      }}
                    >
                      <h5
                        style={{
                          color: 'white',
                          paddingTop: 8,
                          marginLeft: 10
                        }}
                      >
                        {moment(this.state.date).format('dddd Do MMMM')}
                      </h5>
                      <h6
                        style={{
                          color: 'white',
                          marginLeft: 10,
                          paddingTop: 8,
                          fontSize: 14
                        }}
                      >
                        {this.state.partySize + (this.state.partySize > 1 ? ' Guests' : ' Guest')}
                      </h6>
                      <h6
                        style={{
                          color: 'white',
                          marginLeft: 10,
                          fontSize: 14
                        }}
                      >
                        {this.state.timeLabel}
                      </h6>
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '0 17px',
                      marginTop: 25,
                      overflowY: 'auto',
                      width: 280,
                      height: 200,
                      marginBottom: 20
                    }}
                  >
                    <div
                      style={{
                        display: 'block',
                        width: 'fit-content'
                      }}
                    >
                      {availableFloors.map(({ floor_id, floor_name, isActive }, index) => (
                        <Button
                          style={{
                            height: 45,
                            width: '100%',
                            marginRight: 18,
                            marginBottom: 15
                          }}
                          data-floorid={floor_id}
                          data-label={floor_name}
                          name="floor"
                          className="no-margin-right"
                          value={floor_id}
                          variant="contained"
                          color="primary"
                          key={index}
                          onClick={(event) => {
                            this.handleCreateBooking(event);
                          }}
                          disabled={!isActive}
                        >
                          {floor_name}
                        </Button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>
      ) : (
        <div className="radio">
          <div className="section-title">Select preferred section:</div>
          {availableFloors.map(({ floor_id, floor_name, isActive }, index) => (
            <Button
              data-floorid={floor_id}
              data-label={floor_name}
              name="floor"
              value={floor_id}
              variant="contained"
              color="primary"
              key={index}
              onClick={(event) => {
                this.handleCreateBooking(event);
              }}
              disabled={!isActive}
            >
              {this.state.loading ? (
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
              ) : (
                floor_name
              )}
            </Button>
          ))}
          <div></div>
        </div>
      )
    ) : (
      // Make Modal here for allow user to select seat
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={this.state.openSeatSelection}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={this.state.openSeatSelection}>
          <div
            className={classes.paper}
            style={{
              position: 'relative'
            }}
          >
            {!isMobile ? (
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  margin: '0 auto'
                }}
              >
                <div
                  style={{
                    width: 800,
                    height: 490,
                    background: '#24292F',
                    borderRadius: 10,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)'
                  }}
                >
                  <img
                    src={closeBtn}
                    className="d-inline"
                    style={{
                      position: 'absolute',
                      top: 16,
                      right: 16,
                      width: 15,
                      height: 15,
                      filter: 'invert(1)',
                      cursor: 'pointer'
                    }}
                    onClick={(e) =>
                      this.setState({
                        bookingTime: '',
                        selectedSeatFloorTable: [],
                        selectedSeatFloor: null,
                        disabledConfirmButton: true
                      })
                    }
                    alt="checker"
                  />

                  {/* Add the content here */}
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      paddingBottom: 55,
                      paddingTop: 40,
                      display: 'inline-flex'
                    }}
                  >
                    <div
                      style={{
                        width: '70%',
                        zoom: 0.4
                      }}
                    >
                      {/* Add Tables here */}
                      {/* seatFloors */}
                      {/* unavailable */}
                      <div className="table-floor">
                        {selectedSeatFloor !== null && selectedSeatFloor.tables.length > 0
                          ? selectedSeatFloor.tables.map((table) => (
                              <div
                                key={Math.random()}
                                className={`table-container ${
                                  !table.unavailable ? '' : 'unavailable'
                                }`}
                                style={{
                                  position: 'absolute',
                                  top: table.pos_y,
                                  left: table.pos_x,
                                  color: 'white',
                                  fontSize: 10
                                }}
                                onClick={(event) => {
                                  this.selectedTable(table);
                                }}
                              >
                                <div
                                  key={Math.random()}
                                  style={{
                                    position: 'relative',
                                    width: getRadWidth(table.rotate_deg, table.table_type),
                                    height: getRadHeight(table.rotate_deg, table.table_type)
                                  }}
                                >
                                  <span
                                    key={Math.random()}
                                    style={{
                                      position: 'absolute',
                                      zIndex: 1,
                                      left: tableNamePositionLeft(table.table_type),
                                      transform: `translateX(${tableNamePositionLeftTranslate(
                                        table.table_type
                                      )})`,
                                      marginLeft: tableNamePositionMarginLeft(
                                        table.table_type,
                                        table.rotate_deg
                                      ),
                                      marginTop: tableNamePositionMarginTop(
                                        table.table_type,
                                        table.rotate_deg
                                      )
                                    }}
                                  >
                                    {table.name}
                                  </span>
                                  <div
                                    key={Math.random()}
                                    style={{
                                      width: getTableWidth(table.table_type),
                                      height: getTableHeight(table.table_type),
                                      transformOrigin: 'top left',
                                      position: 'absolute',
                                      top: getPositionTop(table.rotate_deg, table.table_type),
                                      left: getPositionLeft(table.rotate_deg, table.table_type),
                                      transform: `rotate(${radToDeg(table.rotate_deg)}deg)`
                                    }}
                                  >
                                    <img
                                      key={Math.random()}
                                      src={getTableImg(table.table_type)}
                                      width={`${getTableWidth(table.table_type)}`}
                                      height={`${getTableHeight(table.table_type)}`}
                                      className="d-inline"
                                      style={
                                        selectedSeatFloorTable !== null
                                          ? selectedSeatFloorTable.filter((i) => i.id === table.id)
                                              .length > 0
                                            ? {
                                                filter: 'invert(1)'
                                              }
                                            : {}
                                          : {}
                                      }
                                      alt="checker"
                                    />
                                  </div>
                                </div>
                              </div>
                            ))
                          : ''}
                      </div>
                    </div>
                    <div
                      style={{
                        width: '30%'
                      }}
                    >
                      <div style={{ width: '100%' }}>
                        <h5
                          style={{
                            color: 'white',
                            fontSize: 12,
                            marginLeft: 17
                          }}
                        >
                          Select a section
                        </h5>
                        <div
                          style={{
                            height: 97,
                            margin: '0px 15px 0 17px',
                            borderRadius: 10,
                            background:
                              'linear-gradient(90deg, rgba(250,75,132,1) 57%, rgba(245,175,151,1) 100%)'
                          }}
                        >
                          <h5
                            style={{
                              color: 'white',
                              paddingTop: 5,
                              marginLeft: 10,
                              fontSize: 16,
                              marginBottom: 0,
                              whiteSpace: 'nowrap',
                              overflowX: 'hidden',
                              overflowY: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                            title={
                              selectedSeatFloorTable !== null
                                ? `${
                                    selectedSeatFloorTable.length > 0
                                      ? selectedSeatFloorTable.map((i) => i.name).join(' | ')
                                      : ''
                                  }`
                                : ''
                            }
                          >
                            {
                              // table name
                              selectedSeatFloorTable !== null
                                ? `${
                                    selectedSeatFloorTable.length > 0
                                      ? selectedSeatFloorTable.map((i) => i.name).join(' | ')
                                      : 'No selected table'
                                  }`
                                : 'No selected table'
                            }
                          </h5>
                          <h6
                            style={{
                              color: 'white',
                              marginLeft: 10,
                              paddingTop: 8,
                              fontSize: 14
                            }}
                          >
                            {selectedSeatFloor !== null
                              ? `on ${selectedSeatFloor.floor_name}`
                              : 'No selected floor'}
                          </h6>
                          <h6
                            style={{
                              color: 'white',
                              marginLeft: 10,
                              fontSize: 12
                            }}
                          >
                            {`Party Size: ${this.state.partySize} ${
                              this.state.partySize > 1 ? 'guests' : 'guest'
                            }`}
                          </h6>

                          <h6
                            style={{
                              color: 'white',
                              marginLeft: 10,
                              fontSize: 12
                            }}
                          >
                            {`Time: ${this.state.timeLabel}`}
                          </h6>
                        </div>
                      </div>
                      {/* Section buttons */}
                      <div
                        style={{
                          margin: '0 17px',
                          marginTop: 25,
                          overflowY: 'auto',
                          height: 200,
                          marginBottom: 20
                        }}
                      >
                        <div
                          style={{
                            display: 'block',
                            width: 'fit-content'
                          }}
                        >
                          {allFloors.map(({ id, floor_name, isActive }, index) => (
                            <Button
                              style={{
                                height: 45,
                                width: '100%',
                                marginBottom: 10
                              }}
                              data-floorid={id}
                              data-label={floor_name}
                              name="floor"
                              className={`no-margin-right ${
                                this.state.selectedSeatFloor
                                  ? id !== this.state.selectedSeatFloor.id
                                    ? 'unselected'
                                    : ''
                                  : 'unselected'
                              }`}
                              value={id}
                              variant="contained"
                              color="primary"
                              key={index}
                              disabled={
                                seatFloors.filter((i) => i.id === id).length > 0 ? false : true
                              }
                              onClick={(event) => {
                                this.displayTablesFromFloor(id);
                              }}
                            >
                              {floor_name}
                            </Button>
                          ))}
                        </div>
                      </div>
                      <div
                        style={{
                          margin: '0 16px 0'
                        }}
                      >
                        <Button
                          style={{
                            height: 45,
                            width: '100%',
                            marginBottom: 10,
                            background: '#27f99d',
                            color: '#0b5032',
                            borderRadius: 25
                          }}
                          className={`no-margin-right confirm ${
                            disabledConfirmButton ? 'Mui-disabled' : ''
                          }`}
                          variant="contained"
                          color="primary"
                          onClick={(event) => {
                            this.handleCreateBooking(event);
                          }}
                          disabled={false}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </div>

                  <Row
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translate(-35%, -50%)'
                    }}
                  >
                    <Col xs lg="12" className="text-center">
                      <p className="booking_widget__powered_by">
                        Powered by{' '}
                        <a href="https://wabify.com/" rel="noopener noreferrer" target="_blank">
                          <img src={logo} alt="logo" />
                        </a>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div
                style={{
                  width: '95%',
                  height: '98%',
                  background: '#24292F',
                  borderRadius: 10,
                  position: 'absolute',
                  top: 10,
                  left: '50%',
                  transform: 'translate(-50%, 0%)'
                }}
              >
                <img
                  src={closeBtn}
                  className="d-inline"
                  style={{
                    position: 'absolute',
                    top: 16,
                    right: 16,
                    width: 15,
                    height: 15,
                    filter: 'invert(1)',
                    cursor: 'pointer'
                  }}
                  onClick={(e) =>
                    this.setState({
                      bookingTime: '',
                      selectedSeatFloorTable: [],
                      selectedSeatFloor: null,
                      disabledConfirmButton: true
                    })
                  }
                  alt="checker"
                />
                {/* Main Container */}
                <div
                  style={{
                    padding: '50px 15px 60px',
                    height: '100%'
                  }}
                >
                  {/* Top */}
                  <div style={{ width: '100%' }}>
                    <h5
                      style={{
                        color: 'white',
                        fontSize: 12,
                        marginLeft: 17
                      }}
                    >
                      Select a section
                    </h5>
                    <div
                      style={{
                        height: 97,
                        margin: '0px 15px 0 17px',
                        borderRadius: 10,
                        background:
                          'linear-gradient(90deg, rgba(250,75,132,1) 57%, rgba(245,175,151,1) 100%)'
                      }}
                    >
                      <h5
                        style={{
                          color: 'white',
                          paddingTop: 5,
                          marginLeft: 10,
                          fontSize: 16,
                          marginBottom: 0,
                          whiteSpace: 'nowrap',
                          overflowX: 'hidden',
                          overflowY: 'hidden',
                          textOverflow: 'ellipsis'
                        }}
                        title={
                          selectedSeatFloorTable !== null
                            ? `${
                                selectedSeatFloorTable.length > 0
                                  ? selectedSeatFloorTable.map((i) => i.name).join(' | ')
                                  : ''
                              }`
                            : ''
                        }
                      >
                        {
                          // table name
                          selectedSeatFloorTable !== null
                            ? `${
                                selectedSeatFloorTable.length > 0
                                  ? selectedSeatFloorTable.map((i) => i.name).join(' | ')
                                  : 'No selected table'
                              }`
                            : 'No selected table'
                        }
                      </h5>
                      <h6
                        style={{
                          color: 'white',
                          marginLeft: 10,
                          paddingTop: 8,
                          fontSize: 14
                        }}
                      >
                        {selectedSeatFloor !== null
                          ? `on ${selectedSeatFloor.floor_name}`
                          : 'No selected floor'}
                      </h6>
                      <h6
                        style={{
                          color: 'white',
                          marginLeft: 10,
                          fontSize: 12
                        }}
                      >
                        {`Party Size: ${this.state.partySize} ${
                          this.state.partySize > 1 ? 'guests' : 'guest'
                        }`}
                      </h6>

                      <h6
                        style={{
                          color: 'white',
                          marginLeft: 10,
                          fontSize: 12
                        }}
                      >
                        {`Time: ${this.state.timeLabel}`}
                      </h6>
                    </div>
                  </div>
                  {/* Middle */}
                  <div
                    style={{
                      width: '100%',
                      padding: '0 15px',
                      marginTop: 10
                    }}
                  >
                    <Select
                      style={{
                        background:
                          'linear-gradient(90deg, rgb(250, 75, 132) 57%, rgb(245, 175, 151) 100%)',
                        borderRadius: 10,
                        width: '100%',
                        color: 'white'
                      }}
                      native
                      value={this.state.selectedSeatFloor.id}
                      onChange={(e) => this.displayTablesFromFloor(e.currentTarget.value)}
                      inputProps={{
                        name: 'selectedFloorId',
                        id: 'selected-floor-id'
                      }}
                    >
                      {seatFloors.map(({ id, floor_name, isActive }, index) => (
                        <option value={id} key={id}>
                          {floor_name}
                        </option>
                      ))}
                    </Select>
                  </div>
                  <div
                    style={{
                      height: '50%',
                      zoom: 0.4,
                      marginTop: 40
                    }}
                    className="table-floor"
                  >
                    {selectedSeatFloor !== null && selectedSeatFloor.tables.length > 0
                      ? selectedSeatFloor.tables.map((table) => (
                          <div
                            key={Math.random()}
                            className={`table-container ${!table.unavailable ? '' : 'unavailable'}`}
                            style={{
                              position: 'absolute',
                              top: table.pos_y,
                              left: table.pos_x,
                              color: 'white',
                              fontSize: 10
                            }}
                            onClick={(event) => {
                              this.selectedTable(table);
                            }}
                          >
                            <div
                              key={Math.random()}
                              style={{
                                position: 'relative',
                                width: getRadWidth(table.rotate_deg, table.table_type),
                                height: getRadHeight(table.rotate_deg, table.table_type)
                              }}
                            >
                              <span
                                key={Math.random()}
                                style={{
                                  position: 'absolute',
                                  zIndex: 1,
                                  left: tableNamePositionLeft(table.table_type),
                                  transform: `translateX(${tableNamePositionLeftTranslate(
                                    table.table_type
                                  )})`,
                                  marginLeft: tableNamePositionMarginLeft(
                                    table.table_type,
                                    table.rotate_deg
                                  ),
                                  marginTop: tableNamePositionMarginTop(
                                    table.table_type,
                                    table.rotate_deg
                                  )
                                }}
                              >
                                {table.name}
                              </span>
                              <div
                                key={Math.random()}
                                style={{
                                  width: getTableWidth(table.table_type),
                                  height: getTableHeight(table.table_type),
                                  transformOrigin: 'top left',
                                  position: 'absolute',
                                  top: getPositionTop(table.rotate_deg, table.table_type),
                                  left: getPositionLeft(table.rotate_deg, table.table_type),
                                  transform: `rotate(${radToDeg(table.rotate_deg)}deg)`
                                }}
                              >
                                <img
                                  key={Math.random()}
                                  src={getTableImg(table.table_type)}
                                  width={`${getTableWidth(table.table_type)}`}
                                  height={`${getTableHeight(table.table_type)}`}
                                  className="d-inline"
                                  style={
                                    selectedSeatFloorTable !== null
                                      ? selectedSeatFloorTable.filter((i) => i.id === table.id)
                                          .length > 0
                                        ? {
                                            filter: 'invert(1)'
                                          }
                                        : {}
                                      : {}
                                  }
                                  alt="checker"
                                />
                              </div>
                            </div>
                          </div>
                        ))
                      : ''}
                  </div>
                  {/* Bottom */}
                  <div
                    style={{
                      width: '80%',
                      position: 'absolute',
                      bottom: 50,
                      left: '50%',
                      transform: 'translate(-50%, 0%)'
                    }}
                  >
                    <Button
                      style={{
                        height: 45,
                        width: '100%',
                        marginBottom: 10,
                        background: '#27f99d',
                        color: '#0b5032',
                        borderRadius: 25
                      }}
                      className={`no-margin-right confirm ${
                        disabledConfirmButton ? 'Mui-disabled' : ''
                      }`}
                      variant="contained"
                      color="primary"
                      onClick={(event) => {
                        this.handleCreateBooking(event);
                      }}
                      disabled={false}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
                {/* Bottom */}
                <Row
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    width: '90%',
                    transform: 'translate(-44%, -50%)'
                  }}
                >
                  <Col xs lg="12" className="text-center">
                    <p className="booking_widget__powered_by">
                      Powered by{' '}
                      <a href="https://wabify.com/" rel="noopener noreferrer" target="_blank">
                        <img src={logo} alt="logo" />
                      </a>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    );
  };

  searchTableResult = (availableTimeSlot) => {
    const { classes } = this.props;
    if (this.state.displaySearchResult) {
      return (
        <div className="radio">
          {availableTimeSlot.map(({ shift_id, value, label, available }, index) => (
            <Button
              data-label={label}
              data-isvailable={available === true ? true : false}
              data-shiftid={shift_id}
              name="bookingTime"
              value={value}
              variant="contained"
              color="primary"
              // onClick={(event) => { this.handleCreateBooking(event) }}
              onClick={(event) => {
                this.handleDisplayFloors(event);
              }}
              key={index}
              disabled={
                !this.state.allowGuestSelectSeat
                  ? available
                    ? false
                    : true
                  : this.state.seatLoading
              }
            >
              {label}
            </Button>
          ))}
        </div>

        // <div style={{width: '100%'}}>
        //   { isMobile ?
        //     availableTimeSlot ?
        //     <div style={{ marginTop: 10, color: '#707070' }}>
        //       <label>Select time slot:</label>
        //       <FormControl className={classes.formControl}>
        //         <Select
        //           style={{ color:'#707070' }}
        //           native
        //           value={this.state.timeLabel}
        //           onChange={(event) => {
        //             this.handleDisplayFloors(event);
        //           }}
        //           inputProps={{
        //             name: 'timeLabel',
        //             id: 'time-label'
        //           }}
        //         >
        //           <option value={null}>
        //             {'Select time'}
        //           </option>
        //           {availableTimeSlot.filter((e) => e.available === true).map(({ shift_id, value, label, available }, index) => (
        //             <option
        //               value={label}
        //               key={value}
        //               data-label={label}
        //               data-shiftid={shift_id}
        //               data-isvailable={available === true ? true : false}
        //               disabled={ available ? false : true }
        //             >
        //               {label}
        //             </option>
        //           ))}
        //         </Select>
        //       </FormControl>
        //     </div>
        //       : ''
        //     :
        //     <div className="radio">
        //       {availableTimeSlot.map(({ shift_id, value, label, available }, index) => (
        //         <Button
        //           data-label={label}
        //           data-isvailable={available === true ? true : false}
        //           data-shiftid={shift_id}
        //           name="bookingTime"
        //           value={value}
        //           variant="contained"
        //           color="primary"
        //           // onClick={(event) => { this.handleCreateBooking(event) }}
        //           onClick={(event) => {
        //             this.handleDisplayFloors(event);
        //           }}
        //           key={index}
        //           disabled={available ? false : true}
        //         >
        //           {label}
        //         </Button>
        //       ))}
        //     </div>
        //   }
        // </div>
      );
    }
  };

  timeSlotResults = (isMobile, timeSlots) => {
    if (isMobile) {
      return (
        <Select
          native
          value={this.state.time}
          onChange={this.handleChangeTime}
          inputProps={{
            name: 'time',
            id: 'time'
          }}
        >
          {timeSlots.map(({ value, label }, index) => (
            <option
              className="widget-select"
              data-label={label}
              value={value}
              selected={value == 25200 ? true : false}
              key={index}
            >
              {label}
            </option>
          ))}

          {/* {timeSlots.length > 0 && (
              this.timeSlotResults(isMobile, timeSlots)
            )} */}
        </Select>
      );
    } else {
      return (
        <Select
          value={this.state.time}
          onChange={this.handleChangeTime}
          inputProps={{
            name: 'time',
            id: 'time'
          }}
        >
          {timeSlots.map(({ value, label }, index) => (
            <MenuItem
              className="widget-select"
              data-label={label}
              value={value}
              selected={value == 25200 ? true : false}
              key={index}
            >
              {label}
            </MenuItem>
          ))}
        </Select>
      );
    }
  };

  futureResults = (futureTimeSlots) => {
    if (futureTimeSlots && this.state.loading === false) {
      return (
        <div className="futureDates">
          {this.state.showAlternate === false ? (
            <div className="text-center">
              <a
                href="javascript:void(0)"
                onClick={() =>
                  this.setState({
                    showAlternate: true
                  })
                }
              >
                View alternate days
              </a>
            </div>
          ) : (
            <div>
              <div>
                <h5>Here are some available time for different dates</h5>
              </div>
              {Object.keys(futureTimeSlots).map((date_index) => (
                <div>
                  <p>{moment(date_index).format('dddd - MMM D, YYYY')}</p>
                  <div className="radio">
                    <div
                      style={{
                        textAlign: 'center',
                        display: 'inline-flex',
                        overflowX: 'auto',
                        maxWidth: '100%'
                      }}
                    >
                      {futureTimeSlots[date_index].map(
                        ({ shift_id, value, label, available }, index) => (
                          <Button
                            data-label={label}
                            data-isvailable={available === true ? true : false}
                            data-shiftid={shift_id}
                            data-date={date_index}
                            name="bookingTime"
                            value={value}
                            variant="contained"
                            color="primary"
                            // onClick={(event) => { this.handleCreateBooking(event) }}
                            onClick={(event) => {
                              this.handleDisplayFloors(event);
                            }}
                            key={index}
                            disabled={available ? false : true}
                          >
                            {label}
                          </Button>
                        )
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      );
    }
  };

  searchFutureTableResult = (futureTimeSlot, date) => {
    if (this.state.displaySearchResult) {
      return (
        <div className="radio">
          <label>{date}</label>
          {futureTimeSlot.map(({ shift_id, value, label, available }, index) => (
            <Button
              data-label={label}
              data-isvailable={available === true ? true : false}
              data-shiftid={shift_id}
              name="bookingTime"
              value={value}
              variant="contained"
              color="primary"
              // onClick={(event) => { this.handleCreateBooking(event) }}
              onClick={(event) => {
                this.handleDisplayFloors(event);
              }}
              key={index}
              disabled={available ? false : true}
            >
              {label}
            </Button>
          ))}
        </div>
      );
    }
  };

  noResultFound = () => {
    let noShiftAvailable = this.state.displayHasNoShift;
    let isSpeciaDays = this.state.displayHasSpecialDay;
    let noResultFound = this.state.displayNoResultFound;

    if (noShiftAvailable && noResultFound) {
      return (
        <div className="radio">
          Sorry there are currently no tables available at this time. Please select a different date
          or time
        </div>
      );
    }

    if (isSpeciaDays && noResultFound) {
      return (
        <div className="radio">
          Sorry we are closed on this date. Please select a different date
        </div>
      );
    }

    if (noResultFound) {
      return (
        <div className="radio">
          At the moment, there’s no online availability within 1.5 hours of {this.state.timeLabel}.
        </div>
      );
    }
  };

  handleCreateBooking = (event) => {
    //    const data = {
    //      userId: this.state.userId,
    //      party_size: this.state.partySize,
    //      date: formatDate(this.state.date, 'YYYY-MM-DD'),
    //      time: this.state.time,
    //      booking_time: this.state.bookingTime,
    //      shift_id: this.state.shiftId,
    //      floor_id: this.state.selectedSeatFloor === null ? parseInt(event.currentTarget.getAttribute('data-floorid')) : this.state.selectedSeatFloor.id,
    //      experience_id: this.state.experience_id,
    //      tables_ids: this.state.selectedSeatFloorTable.map(i => i.id)
    //    };
    //
    //    axios({
    //      method: 'POST',
    //      url: `${URL}/widget/booking`,
    //      headers: {
    //          'Content-Type': 'application/json',
    //          'Authorization': `Bearer ${this.state.key}`
    //      },
    //      data: data
    //    })
    //      .then((response) => {
    //        let responseData = response.data.data;
    if (this.state.loading === false) {
      this.setState({
        loading: true
      });
      var floorid = event.currentTarget.getAttribute('data-floorid');
      var floorname = event.currentTarget.getAttribute('data-label');
      if (floorid === NaN || floorid === null) {
        floorid = this.state.selectedSeatFloor.id;
      }

      const data = {
        userId: this.state.userId,
        party_size: this.state.partySize,
        date: formatDate(this.state.date, 'YYYY-MM-DD'),
        time: this.state.time,
        booking_time: this.state.bookingTime,
        shift_id: this.state.shiftId,
        experience_id: this.state.experience_id,
        experience_name: this.state.experience_name,
        price: this.state.experience_price,
        widgetNoteTitle: this.state.widgetMessageTitle,
        widgetNoteMessage: this.state.widgetMessage,
        onlinePaymentFee: this.state.onlinePaymentFee,
        floor_id: floorid, //parseInt(event.currentTarget.getAttribute('data-floorid')),
        experience_id: this.state.experience_id
      };

      axios({
        method: 'POST',
        url: `${URL}/widget/booking`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.state.key}`
        },
        data: data
      })
        .then((response) => {
          this.setState({
            loading: false
          });
          let responseData = response.data.data;
          const bookingData = {
            date: formatDate(this.state.date, 'dddd, D MMM'),
            time: this.state.timeLabel,
            partySize: this.state.partySize,
            floor_name: floorname,
            createdAt: responseData.booking.created_at,
            shift_id: this.state.shiftId,
            experience_id: this.state.experience_id,
            experience_name: this.state.experience_name,
            price: this.state.experience_price,
            widgetNoteTitle: this.state.widgetMessageTitle,
            widgetNoteMessage: this.state.widgetMessage,
            onlinePaymentFee: this.state.onlinePaymentFee
          };

          this.props.history.push({
            pathname: `/step2/${responseData.booking.id}/${this.state.key}`,
            bookingData: bookingData
          });
        })
        .catch((error) => {
          this.setState({
            loading: false
          });
          if (error.response) {
            let message = error.response.data.message;
            this.handleClickOpen(message);
          } else {
            this.handleClickOpen(error);
          }
        });
    }
  };

  selecExperience = (event) => {
    let experienceId = event.currentTarget.getAttribute('data-experience-id');
    let experienceName = event.currentTarget.getAttribute('data-experience-name');

    this.getDateTimeslots(this.state.date, experienceId);

    let remainingTicket = event.currentTarget.getAttribute('data-remaining-ticket');

    if (remainingTicket == 0) {
      this.handleClickOpen(experienceName);
      return;
    }

    let experience = this.state.experiences.find((i) => i.id === Number(experienceId));
    let experienceConnection = experience.experience_connection;
    let shift = experienceConnection.shift;
    let experienceStartDate = moment(experienceConnection.start_date);
    let experienceEndDate = moment(experienceConnection.end_date);
    let currentDate = moment();

    let selectedDate = null;

    if (!experienceEndDate.isValid()) {
      selectedDate =
        currentDate.valueOf() >= experienceStartDate.valueOf()
          ? currentDate.toDate()
          : experienceStartDate.toDate();
    } else {
      selectedDate =
        currentDate.valueOf() >= experienceStartDate.valueOf() &&
        currentDate.valueOf() <= experienceEndDate.valueOf()
          ? currentDate.toDate()
          : experienceStartDate.toDate();
    }

    this.setState({
      experience_id: experienceId,
      experience_name: experienceName,
      view_state: VIEWSTATE.TABLE_BOOKING,
      experienceStartDate: selectedDate,
      time: shift.time_range[0].value,
      experienceEndTime: shift.end_time,
      futureTime: shift.time_range,
      experience_price: experience.price
    });
  };

  experienceState = (event) => {
    this.setState({
      view_state: VIEWSTATE.EXPERIENCE
    });
  };

  goBackToOption = (event) => {
    this.setState({
      view_state: VIEWSTATE.INITIAL
    });
  };

  bookingState = (event) => {
    this.setState({
      view_state: VIEWSTATE.TABLE_BOOKING
    });
  };

  disableDays = (day) => {
    let experience = this.state.experiences.find((i) => i.id === Number(this.state.experience_id));
    let experienceConnection = experience.experience_connection;

    if (experience.experience_connection === null) {
      return false;
    }

    let date = moment(day).startOf('day').toDate();
    let currentDate = moment().startOf('day');
    let endDate = moment(experienceConnection.end_date).startOf('day');
    let startDate = moment(experienceConnection.start_date).startOf('day');
    var exist = false;

    if (experienceConnection.recurring_type === RECURRINGTYPE.NONE) {
      if (!endDate.isValid()) {
        exist = date.getTime() === startDate.valueOf();
      } else {
        exist =
          date.getTime() >= startDate.valueOf() &&
          date.getTime() <= endDate.valueOf() &&
          startDate.valueOf() >= currentDate.valueOf() &&
          endDate.valueOf() >= currentDate.valueOf();
      }

      return !exist ? true : false;
    }

    if (experienceConnection.recurring_type === RECURRINGTYPE.WEEKLY) {
      if (!endDate.isValid()) {
        // If `end_date` is null
        // Indefinite date
        let daysOfWeek = experienceConnection.day_of_week;

        exist =
          daysOfWeek.includes(moment(date).isoWeekday()) && startDate.valueOf() <= date.getTime();

        return !exist ? true : false;
      } else {
        // If `end_date` is not null
        // Definite date
        let daysOfWeek = experienceConnection.day_of_week;
        // let endDate = moment(endDate)

        if (date.getTime() <= endDate.valueOf()) {
          exist =
            daysOfWeek.includes(moment(date).isoWeekday()) &&
            startDate.valueOf() <= date.getTime() &&
            endDate.valueOf() >= date.getTime();
        }

        return !exist ? true : false;
      }
    }

    if (experienceConnection.recurring_type === RECURRINGTYPE.MONTHLY) {
      if (experienceConnection.recurring_value === RECURRINGVALUE.EVERYDAYOFMONTH) {
        // Every Day of week of the month
        let startDateDayOfWeek = moment(startDate).isoWeekday();
        let dateDayOfWeek = moment(day).isoWeekday();

        var startDateWeekOfMonth = startDate.week() - moment(startDate).startOf('month').week() + 1;
        startDateWeekOfMonth = startDateWeekOfMonth === 0 ? 1 : startDateWeekOfMonth;

        var dateWeekOfMonth = moment(date).week() - moment(date).startOf('month').week() + 1;
        dateWeekOfMonth = dateWeekOfMonth === 0 ? 1 : dateWeekOfMonth;

        let startMonth = startDate.month();
        let endMonth = endDate.month();
        let currentMonthOfCalendar = moment(day).startOf('month').month();
        let currentDate = moment();
        let __date = moment(day);
        if (!endDate.isValid()) {
          exist = startDateWeekOfMonth === dateWeekOfMonth && startDateDayOfWeek === dateDayOfWeek;
        } else {
          exist =
            currentMonthOfCalendar >= startMonth &&
            currentMonthOfCalendar <= endMonth &&
            startDateWeekOfMonth === dateWeekOfMonth &&
            startDateDayOfWeek === dateDayOfWeek &&
            __date.unix() >= currentDate.unix();
        }

        return !exist ? true : false;
      }

      if (experienceConnection.recurring_value === RECURRINGVALUE.EVERYDATEOFMONTH) {
        let startDateOfMonth = moment(startDate).date();
        let endDateOfMonth = moment(endDate).date();
        let currentDateOfMonth = moment(day).date();

        let startMonth = startDate.month();
        let startYear = startDate.year();
        let endMonth = endDate.month();
        let endYear = endDate.year();
        let currentMonthOfCalendar = moment(day).startOf('month').month();
        let dayYear = moment(day).year();
        let dayTimestamp = moment(day).unix();
        let currentTimestamp = moment().unix();

        if (!endDate.isValid()) {
          exist =
            startDateOfMonth === currentDateOfMonth &&
            startYear <= dayYear &&
            currentTimestamp <= dayTimestamp;
        } else {
          exist =
            startDateOfMonth <= currentDateOfMonth &&
            endDateOfMonth >= currentDateOfMonth &&
            startMonth <= currentMonthOfCalendar &&
            endMonth >= currentMonthOfCalendar &&
            startYear <= dayYear &&
            endYear >= dayYear &&
            startDateOfMonth === currentDateOfMonth &&
            dayTimestamp >= currentTimestamp;
        }

        return !exist ? true : false;
      }
    }

    if (experienceConnection.recurring_type === RECURRINGTYPE.ANNUALLY) {
      if (experienceConnection.recurring_value === RECURRINGVALUE.EVERYMONTHDATEOFYEAR) {
        let startDateOfMonth = moment(startDate).date();
        let endDateOfMonth = moment(endDate).date();
        let currentDateOfMonth = moment(day).date();

        let startMonth = startDate.month();
        let endMonth = endDate.month();
        let currentMonthOfCalendar = moment(day).startOf('month').month();

        let startYear = startDate.year();
        let endYear = endDate.year();
        let currentYear = moment(day).year();

        if (!endDate.isValid()) {
          exist =
            startDateOfMonth === currentDateOfMonth &&
            startMonth === currentMonthOfCalendar &&
            startYear <= currentYear;
        } else {
          exist =
            startDateOfMonth <= currentDateOfMonth &&
            endDateOfMonth >= currentDateOfMonth &&
            startMonth <= currentMonthOfCalendar &&
            endMonth >= currentMonthOfCalendar &&
            startYear <= currentYear &&
            endYear >= currentYear;
        }

        return !exist ? true : false;
      }
    }
  };

  daySuffix = (day) => {
    switch (day) {
      case 1:
        return 'Mon';
      case 2:
        return 'Tue';
      case 3:
        return 'Wed';
      case 4:
        return 'Thurs';
      case 5:
        return 'Fri';
      case 6:
        return 'Sat';
      case 7:
        return 'Sun';
      default:
        break;
    }
  };

  monthName = (month) => {
    switch (month) {
      case 0:
        return 'Jan';
      case 1:
        return 'Feb';
      case 2:
        return 'Mar';
      case 3:
        return 'Apr';
      case 4:
        return 'May';
      case 5:
        return 'Jun';
      case 6:
        return 'Jul';
      case 7:
        return 'Aug';
      case 8:
        return 'Sept';
      case 9:
        return 'Oct';
      case 10:
        return 'Nov';
      case 11:
        return 'Dec';
      default:
        break;
    }
  };

  displayAvailability = (experience_connection) => {
    if (experience_connection == null) {
      return;
    }
    let endDate = moment(experience_connection.end_date).startOf('day');
    let startDate = moment(experience_connection.start_date).startOf('day');
    if (experience_connection.recurring_type === RECURRINGTYPE.NONE) {
      return `Only available on ${formatDate(experience_connection.start_date, 'YYYY-MM-DD')}`;
    }

    if (experience_connection.recurring_type === RECURRINGTYPE.WEEKLY) {
      let daysOfWeek = experience_connection.day_of_week.map((e) => this.daySuffix(e));
      return `Available every ${daysOfWeek.join(', ')}`;
    }

    if (experience_connection.recurring_type === RECURRINGTYPE.MONTHLY) {
      if (experience_connection.recurring_value === RECURRINGVALUE.EVERYDAYOFMONTH) {
        let startDateDayOfMonth = startDate.date();

        if (!endDate.isValid()) {
          return `Every ${this.ordinal_suffix_of(startDateDayOfMonth)} of the month`;
        } else {
          return `Event ${this.ordinal_suffix_of(
            startDateDayOfMonth
          )} of the month until ${formatDate(experience_connection.end_date, 'MMM YYYY')}`;
        }
      }

      if (experience_connection.recurring_value === RECURRINGVALUE.EVERYNUMWEEKOFMONTH) {
        let startDateWeekOfMonth = startDate.week() - moment(startDate).startOf('month').week() + 1;
        let startDateDayOfWeek = moment(startDate).isoWeekday();

        if (!endDate.isValid()) {
          return `Every ${this.ordinal_suffix_of(startDateWeekOfMonth)} ${this.daySuffix(
            startDateDayOfWeek
          )} of month`;
        } else {
          return `Every ${this.ordinal_suffix_of(startDateWeekOfMonth)} ${this.daySuffix(
            startDateDayOfWeek
          )} of month until ${formatDate(experience_connection.end_date, 'MMM DD, YYYY')}`;
        }
      }
    }

    if (experience_connection.recurring_type === RECURRINGTYPE.ANNUALLY) {
      if (experience_connection.recurring_value === RECURRINGVALUE.EVERYMONTHDATEOFYEAR) {
        let startDateOfMonth = startDate.date();
        let startMonth = startDate.month();

        if (!endDate.isValid()) {
          return `${this.monthName(startMonth)} ${this.ordinal_suffix_of(
            startDateOfMonth
          )} every year`;
        } else {
          return `${this.monthName(startMonth)} ${this.ordinal_suffix_of(
            startDateOfMonth
          )} every year until ${formatDate(experience_connection.start_date, 'YYYY-MM-DD')}`;
        }
      }
    }
  };

  ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + 'st';
    }
    if (j === 2 && k !== 12) {
      return i + 'nd';
    }
    if (j === 3 && k !== 13) {
      return i + 'rd';
    }
    return i + 'th';
  };

  openCloseCalendar = () => {
    this.setState({
      openCalendar: !this.state.openCalendar
    });
  };

  onDaySelected = (date) => {
    this.setState({
      date: date
    });
    this.openCloseCalendar();
    this.getDateTimeslots(date, this.state.experience_id);
  };

  render() {
    const { classes } = this.props;
    const futureTime = this.state.futureTime;
    const partySizes = this.state.partySizes;
    const availableTimeSlot = this.state.availableTimeSlot;
    const futureTimeSlots = this.state.futureTimeSlots;
    const bookingTime = this.state.bookingTime;
    const selectedFloors = this.state.selectedFloors;
    const allFloors = this.state.allFloors;
    const timeSlots = this.state.timeSlots;

    const state = this.state.view_state;
    const experiences = this.state.experiences;
    const experience_id = this.state.experience_id;
    const experienceStartDate = this.state.experienceStartDate;

    if (state === VIEWSTATE.LOADING) {
      return (
        <Row>
          <Col style={{ textAlign: 'center' }} xs="12" lg="12">
            {/* <Loader type="Circles" color="#00BFFF" height={80} width={80}/> */}
          </Col>
        </Row>
      );
    } else if (state === VIEWSTATE.EXPERIENCE) {
      return (
        <div className={classes.experienceContainer}>
          <Row className="header">
            <Col>
              <img src={logoImg} alt="logo" />
            </Col>
            <Col className={classes.experienceContainerHeader}>
              <img className="close_btn" src={closeBtn} onClick={this.goBackToOption} />
            </Col>
          </Row>
          <Grid container justify="center" className={classes.gridContainer} spacing={3}>
            {experiences.map((item) => (
              <Grid
                style={{ position: 'relative' }}
                key={item.id}
                data-experience-name={item.exp_name}
                data-experience-id={item.id}
                data-remaining-ticket={item.no_of_ticket - item.ticket_sold}
                item
                onClick={this.selecExperience}
              >
                <Paper className={classes.experienceItem + ' experience-item'}>
                  <div>{item.exp_name}</div>
                  <div
                    style={{
                      fontSize: 11
                    }}
                  >
                    <i>{this.displayAvailability(item.experience_connection)}</i>
                  </div>
                  <div className={classes.expDesc}>{item.exp_description}</div>
                  <Grid
                    container
                    style={{
                      position: 'absolute',
                      bottom: 15,
                      width: 174
                    }}
                  >
                    <Grid item xs={4}>
                      ${Math.ceil(item.price)}
                    </Grid>
                    <Grid style={{ fontSize: 12 }} className={classes.right} item xs={8}>
                      {item.no_of_ticket == null
                        ? 'Unlimited'
                        : `${item.no_of_ticket - item.ticket_sold} tickets remaining`}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={this.state.openDialog}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{`Ticket sold out`}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Sorry, we have sold out of all tickets for{' '}
                <strong>{`${this.state.dialogTitle}`}</strong>.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    } else if (state === VIEWSTATE.INITIAL) {
      return (
        <div className="booking_widget__option">
          <Row className="header" style={{ marginBottom: 20 }}>
            <Col>
              <img src={logoImg} alt="logo" />
            </Col>
            <Col className="right"></Col>
          </Row>

          <Grid container justify="center" spacing={3}>
            <Grid item onClick={this.experienceState}>
              <div className="bg-option">
                <label>Book Experience</label>
              </div>
            </Grid>
            <Grid item onClick={this.bookingState}>
              <div className="bg-option">
                <label>Book Table</label>
              </div>
            </Grid>
          </Grid>

          {/* <Row className="content">
                        <Col className="left">

                        </Col>
                        <Col className="right">

                        </Col>
                    </Row> */}
        </div>
      );
    } else if (state === VIEWSTATE.TABLE_BOOKING) {
      if (experience_id === null) {
        return (
          <div
            className={
              isBrowser ? 'booking_widget__app step1 desktop' : 'booking_widget__app step1'
            }
          >
            <div className="content">
              <h2>{this.state.title}</h2>

              <ol className="sub-head">
                <li>
                  <div style={{ width: '100%', textAlign: 'center' }}>1. Find a table</div>
                </li>
                <li>
                  <div style={{ width: '100%', textAlign: 'center' }}>2. Your Details</div>
                </li>
              </ol>

              <form
                className={classes.root + ' root_form'}
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <FormControl className={classes.formControl}>
                  {isMobile ? (
                    <Select
                      native
                      value={this.state.partySize}
                      onChange={this.handlePartySize}
                      inputProps={{
                        name: 'partySize',
                        id: 'party-size'
                      }}
                    >
                      {partySizes.map((value) => (
                        <option value={value} key={value}>
                          {value}
                          {value === 1 ? ' person' : ' people'}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      value={this.state.partySize}
                      onChange={this.handlePartySize}
                      inputProps={{
                        name: 'partySize',
                        id: 'party-size'
                      }}
                    >
                      {partySizes.map((value) => (
                        <MenuItem value={value} key={value}>
                          {value}
                          {value === 1 ? ' person' : ' people'}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                <FormControl className={classes.formControl}>
                  {!isMobile || isTablet ? (
                    <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="D MMM yyyy"
                      placeholder={`${formatDate(this.state.date, 'D MMM yyyy')}`}
                      value={this.state.date}
                      onDayChange={this.handleDateChange}
                      // showOverlay={true}
                      // keepFocus={true}
                      dayPickerProps={{
                        modifiers: {
                          disabled: [
                            {
                              before: new Date() // this.state.date
                            }
                          ]
                        }
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        cursor: 'pointer',
                        height: 59,
                        padding: '18px 20px',
                        fontSize: 16,
                        color: '#707070'
                      }}
                      onClick={this.openCloseCalendar}
                    >
                      <span>{`${formatDate(this.state.date, 'D MMM yyyy')}`}</span>
                    </div>
                  )}
                  {isMobile || !isTablet ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={this.state.openCalendar}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500
                      }}
                    >
                      <Fade in={this.state.openCalendar}>
                        <div
                          className={classes.paper}
                          style={{
                            position: 'relative'
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              margin: '0 auto'
                            }}
                          >
                            <div
                              style={{
                                width: 316,
                                // height: 490,
                                background: '#000000bf',
                                borderRadius: 10,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                              }}
                            >
                              <img
                                src={logoImg}
                                className="d-inline"
                                style={{
                                  position: 'absolute',
                                  top: 14,
                                  left: 16,
                                  width: 75,
                                  height: 21
                                }}
                                alt="checker"
                              />
                              <img
                                src={closeBtn}
                                className="d-inline"
                                style={{
                                  position: 'absolute',
                                  top: 16,
                                  right: 16,
                                  width: 15,
                                  height: 15,
                                  filter: 'invert(1)',
                                  cursor: 'pointer'
                                }}
                                onClick={this.openCloseCalendar}
                                alt="checker"
                              />

                              {/* Main Content */}
                              <DayPicker
                                month={this.state.date}
                                onDayClick={this.onDaySelected}
                                selectedDays={this.state.date}
                                modifiers={{
                                  disabled: {
                                    before: new Date() //this.state.date
                                  }
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </Modal>
                  ) : (
                    ''
                  )}
                </FormControl>
                <FormControl className={classes.formControl}>
                  {this.timeSlotResults(isMobile, timeSlots)}
                </FormControl>

                {/* on Mobile Browser */}
                {/* {availableTimeSlot.length > 0 && isMobile && (
                  this.searchTableResult(availableTimeSlot)
                )} */}

                {/* on Mobile Browser Floor/Section */}
                {/* {isMobile ? bookingTime === '' ? '' : <div style={{ width:'100%' }}>{this.displayAvailableFloorsBtns(allFloors, selectedFloors)}</div> : ''} */}
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="submit"
                  style={{ width: isMobile ? '100%' : '175px' }}
                >
                  {this.state.loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    'Search a Table'
                  )}
                </Button>
              </form>

              <div className="sweet-loading">
                <BounceLoader
                  css={override}
                  size={30}
                  color={'#FE7D86'}
                  loading={this.state.loading}
                />
              </div>

              {/* on Web Browser */}
              {availableTimeSlot.length > 0 && this.searchTableResult(availableTimeSlot)}

              {this.noResultFound()}

              {/* on Web Browser floor/section */}
              {bookingTime === '' ? '' : this.displayAvailableFloorsBtns(allFloors, selectedFloors)}

              {futureTimeSlots && this.futureResults(futureTimeSlots)}
              <Row>
                <Col xs lg="12" className="text-center">
                  <p className="booking_widget__powered_by">
                    Powered by{' '}
                    <a href="https://wabify.com/" rel="noopener noreferrer" target="_blank">
                      <img src={logo} alt="logo" />
                    </a>
                  </p>
                </Col>
              </Row>
            </div>

            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.dialogTitle}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      } else {
        return (
          <div
            className={
              isBrowser ? 'booking_widget__app step1 desktop' : 'booking_widget__app step1'
            }
          >
            <div className="content">
              <h2>{this.state.title}</h2>

              <ol className="sub-head">
                <li>
                  <div style={{ width: '100%', textAlign: 'center' }}>1. Find a table</div>
                </li>
                <li>
                  <div style={{ width: '100%', textAlign: 'center' }}>2. Your Details</div>
                </li>
              </ol>

              <form
                className={classes.root + ' root_form'}
                autoComplete="off"
                onSubmit={this.handleSubmit}
              >
                <FormControl className={classes.formControl}>
                  {/* <Select
                    value={this.state.partySize}
                    onChange={this.handlePartySize}
                    inputProps={{
                      name: 'partySize',
                      id: 'party-size'
                    }}
                  >
                    {partySizes.map((value) => (
                      <MenuItem value={value} key={value}>
                        {value}
                        {value === 1 ? ' person' : ' people'}
                      </MenuItem>
                    ))}
                  </Select> */}
                  {isMobile ? (
                    <Select
                      native
                      value={this.state.partySize}
                      onChange={this.handlePartySize}
                      inputProps={{
                        name: 'partySize',
                        id: 'party-size'
                      }}
                    >
                      {partySizes.map((value) => (
                        <option value={value} key={value}>
                          {value}
                          {value === 1 ? ' person' : ' people'}
                        </option>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      value={this.state.partySize}
                      onChange={this.handlePartySize}
                      inputProps={{
                        name: 'partySize',
                        id: 'party-size'
                      }}
                    >
                      {partySizes.map((value) => (
                        <MenuItem value={value} key={value}>
                          {value}
                          {value === 1 ? ' person' : ' people'}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
                <FormControl className={classes.formControl}>
                  {/* <DayPickerInput
                    formatDate={formatDate}
                    parseDate={parseDate}
                    format="D MMM yyyy"
                    placeholder={`${formatDate(experienceStartDate, 'D MMM yyyy')}`}
                    value={experienceStartDate}
                    onDayChange={this.handleDateChange}
                    // disabledDays={this.disableDays}
                    dayPickerProps={{
                      // enableOutsideDays: false,
                      disabledDays: this.disableDays
                    }}
                  /> */}
                  {!isMobile || isTablet ? (
                    <DayPickerInput
                      formatDate={formatDate}
                      parseDate={parseDate}
                      format="D MMM yyyy"
                      placeholder={`${formatDate(experienceStartDate, 'D MMM yyyy')}`}
                      value={this.state.date}
                      onDayChange={this.handleDateChange}
                      // showOverlay={true}
                      // keepFocus={true}
                      dayPickerProps={{
                        // enableOutsideDays: false,
                        disabledDays: this.disableDays
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        cursor: 'pointer',
                        height: 59,
                        padding: '18px 20px',
                        fontSize: 16,
                        color: '#707070'
                      }}
                      onClick={this.openCloseCalendar}
                    >
                      <span>{`${formatDate(this.state.date, 'D MMM yyyy')}`}</span>
                    </div>
                  )}
                  {isMobile || !isTablet ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      className={classes.modal}
                      open={this.state.openCalendar}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500
                      }}
                    >
                      <Fade in={this.state.openCalendar}>
                        <div
                          className={classes.paper}
                          style={{
                            position: 'relative'
                          }}
                        >
                          <div
                            style={{
                              width: '100%',
                              height: '100%',
                              margin: '0 auto'
                            }}
                          >
                            <div
                              style={{
                                width: 316,
                                // height: 490,
                                background: '#000000bf',
                                borderRadius: 10,
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)'
                              }}
                            >
                              <img
                                src={logoImg}
                                className="d-inline"
                                style={{
                                  position: 'absolute',
                                  top: 14,
                                  left: 16,
                                  width: 75,
                                  height: 21
                                }}
                                alt="checker"
                              />
                              <img
                                src={closeBtn}
                                className="d-inline"
                                style={{
                                  position: 'absolute',
                                  top: 16,
                                  right: 16,
                                  width: 15,
                                  height: 15,
                                  filter: 'invert(1)',
                                  cursor: 'pointer'
                                }}
                                onClick={this.openCloseCalendar}
                                alt="checker"
                              />

                              {/* Main Content */}
                              <DayPicker
                                month={this.state.date}
                                onDayClick={this.onDaySelected}
                                selectedDays={this.state.date}
                                disabledDays={this.disableDays}
                              />
                            </div>
                          </div>
                        </div>
                      </Fade>
                    </Modal>
                  ) : (
                    ''
                  )}
                </FormControl>
                <FormControl className={classes.formControl}>
                  {/* <Select
                    value={this.state.time}
                    onChange={this.handleChangeTime}
                    inputProps={{
                      name: 'time',
                      id: 'time'
                    }}
                  >
                    {futureTime.map(({ value, label }, index) => (
                      <MenuItem data-label={label} value={value} key={index}>
                        {label}
                      </MenuItem>
                    ))}
                  </Select> */}
                  {this.timeSlotResults(isMobile, timeSlots)}
                  {/* { isMobile ?
                    <Select
                      native
                      value={this.state.time}
                      onChange={this.handleChangeTime}
                      inputProps={{
                        name: 'time',
                        id: 'time'
                      }}
                    >
                      {futureTime.map(({ value, label }, index) => (
                        <option 
                          className="widget-select" 
                          data-label={label} 
                          value={value} 
                          selected={value == 25200 ? true : false}
                          key={index}>
                          {label}
                        </option>
                      ))}
                    </Select>
                    :
                    <Select
                      value={this.state.time}
                      onChange={this.handleChangeTime}
                      inputProps={{
                        name: 'time',
                        id: 'time'
                      }}
                    >
                      {futureTime.map(({ value, label }, index) => (
                        <MenuItem 
                          className="widget-select" 
                          data-label={label} 
                          value={value} 
                          selected={value == 25200 ? true : false}
                          key={index}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  } */}
                </FormControl>

                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  className="submit"
                  disabled={!this.state.allowGuestSelectSeat ? false : this.state.seatLoading}
                >
                  Search a Table
                </Button>
              </form>

              <div className="sweet-loading">
                <BounceLoader
                  css={override}
                  size={30}
                  color={'#FE7D86'}
                  loading={this.state.loading}
                />
              </div>

              {availableTimeSlot.length === 0
                ? this.noResultFound()
                : this.searchTableResult(availableTimeSlot)}
              {bookingTime === '' ? '' : this.displayAvailableFloorsBtns(allFloors, selectedFloors)}

              <Row>
                <Col xs lg="12" className="text-center">
                  <p className="booking_widget__powered_by">
                    Powered by{' '}
                    <a href="https://wabify.com/" rel="noopener noreferrer" target="_blank">
                      <img src={logo} alt="logo" />
                    </a>
                  </p>
                </Col>
              </Row>
            </div>

            <Dialog
              open={this.state.openDialog}
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title"></DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {this.state.dialogTitle}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
    }
  }
}

export default withStyles(styles)(Step1);
