import React, { useState, useMemo, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Box, Typography, Button, Container, Modal } from '@material-ui/core';
import TicketSelection from './TicketSelection';
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';
import EventTips from '../utils/EventTips';

import { format } from 'date-fns';
import createBookingTicket from '../../hooks/@events/createBookingTicket';
import MessageDialog from './common/MessageDialog';
import iconWabi from '../../assets/images/icon-wabi.png';

function TicketFilter({
  isAside = false,
  isDialog = false,
  openFilterDialog,
  propTicketCountByName,
  ticketData,
  businessProfile,
  widgetBranding,
  eventData
}) {
  const history = useHistory();
  const { name, startDate, types } = ticketData;
  const [countByName, setCountByName] = useState({});
  const { key } = useParams();
  const handleCountChange = (name, param) => {
    setCountByName({ ...countByName, [name]: param });
  };

  useEffect(() => {
    if (propTicketCountByName) setCountByName(propTicketCountByName);
  }, [propTicketCountByName]);
  useEffect(() => {
    if (propTicketCountByName) return;
    if (types) {
      setCountByName(
        types.reduce((acc, type) => {
          acc[type.name] = {
            number: 0,
            price: type.price
          };
          return acc;
        }, {})
      );
    }
  }, [types]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  /**
   * Updates the total sum and enables/disables a button based on the countByName object.
   * @param {Object} countByName - An object containing counts for each item.
   * @param {boolean} isDisabled - State indicating whether the button should be disabled.
   * @param {Function} setIsDisabled - Function to update the isDisabled state.
   * @param {number} totalPrice - Total price calculated based on counts.
   * @param {Function} setTotalPrice - Function to update the totalPrice state.
   */
  useEffect(() => {
    let totalSum = 0;
    let totalNum = 0;
    for (let key in countByName) {
      totalSum += countByName[key].number * countByName[key].price;
      totalNum += countByName[key].number;
    }
    if (totalNum > 0) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
    setTotalPrice(totalSum);
  }, [countByName]);
  /**
   * Handles the creation of a booking by formatting date and time,
   * preparing the booking data, and navigating to the next step.
   */
  async function handleCreateBooking(propTicketQuantitiy) {
    const date = new Date(startDate.replace(' ', 'T'));
    /**
     * Options for formatting the time in 12-hour format.
     * @type {Object}
     */
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    const time = date.toLocaleTimeString('en-US', options);
    /**
     * Data object for the ticket booking.
     * @type {Object}
     * @property {string} date - The formatted start date.
     * @property {string} time - The formatted start time.
     * @property {Object} countByName - The count by name details.
     * @property {string} createdAt - The timestamp of booking creation.
     * @property {number} ticket_id - The ID of the ticket.
     * @property {string} ticket_name - The name of the ticket.
     * @property {number} price - The total price of the ticket.
     * @property {string} businessName - The name of the business.
     * @property {number} onlinePaymentFee - The online payment fee.
     */
    const ticketBookingData = {
      date: format(new Date(ticketData.startDate), 'yyyy-MM-dd'),
      time: time,
      countByName: countByName,
      createdAt: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      ticket_id: ticketData.id,
      ticket_name: name,
      price: totalPrice,
      businessName: businessProfile.user.business_profile.business_name,
      onlinePaymentFee: 1
    };

    function formatCountByName(countByName) {
      const ticket_type = [];

      for (const key in countByName) {
        if (countByName.hasOwnProperty(key)) {
          const item = countByName[key];
          if (item && typeof item === 'object' && 'id' in item && 'number' in item) {
            ticket_type.push({
              id: item.id,
              quantity: item.number
            });
          }
        }
      }
      return ticket_type;
    }

    const ticketType = formatCountByName(countByName);
    let data = {
      ticket_id: ticketData.id,
      amount: totalPrice,
      ticket_type: ticketType
    };

    try {
      const ticketBookingResponse = await createBookingTicket(key, data);
      const newUrl = `/step2/${ticketBookingResponse.data.uuid}/${key}`;

      history.push({
        pathname: newUrl,
        ticketBookingData: ticketBookingData
      });
    } catch (error) {
      // API error
      setDialogOpen(true); // Show the Dialog
      console.error('API call error:', error);
    }
  }

  const containerStyle = (() => {
    if (isAside) {
      return {
        backgroundColor: widgetBranding?.background_colour || '#000',
        color: widgetBranding?.accent_colour || '#fff',
        border: '1px solid',
        borderRadius: '10px',
        borderColor: widgetBranding?.accent_colour || '',

        padding: '16px',
        position: 'relative',
        minWidth: '290px',
        width: '290px',
        margin: 'unset'
      };
    } else if (isDialog) {
      return {
        backgroundColor: widgetBranding?.background_colour || '#000',
        color: widgetBranding?.accent_colour || '#fff',
        padding: '16px'
      };
    } else {
      return {
        backgroundColor: widgetBranding?.background_colour || '#000',
        color: widgetBranding?.accent_colour || '#fff',
        padding: '0px',
        position: 'relative',
        padding: '32px 24px 12px'
      };
    }
  })();

  const currentDate = new Date();

  const eventEndDate = new Date(eventData?.data?.endDate.replace(' ', 'T'));
  const eventStartDate = new Date(eventData?.data?.startDate.replace(' ', 'T'));

  return (
    <Container maxWidth="lg" style={containerStyle}>
      <div className="dialog-head">
        <Typography
          style={{
            fontSize: '12px',
            padding: '0 0 8px 8px'
          }}
        >
          Choose youre tickets
        </Typography>
        <img src={iconWabi} alt="Wabi Icon" style={{ width: '45px' }} />
      </div>
      {types &&
        types.length > 0 &&
        types.map((type) => (
          <TicketSelection
            propTicketCountByName={propTicketCountByName}
            key={type.id}
            typeId={type.id}
            name={type.name}
            price={type.price}
            limit={type.limit}
            onCountChange={handleCountChange}
            isAside={isAside}
            widgetBranding={widgetBranding}
          />
        ))}
      {isDialog ? (
        <>
          <EventTips
            style={{ padding: '16px' }}
            startTime={startDate}
            eventData={eventData}
            businessName={businessProfile.user.business_profile.business_name}
            address={businessProfile.user.business_profile.address}
            widgetBranding={widgetBranding}
          />
          <Box style={{ padding: '16px 0', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box padding="0px 24px 16px 24px">
              <Typography variant="body1" gutterBottom>
                Order Summary
              </Typography>
              {types.map((type) => (
                <Typography style={{ fontSize: '12px' }} key={type.id}>
                  {countByName[type.name]?.number ?? 0} x {type.name} Ticket $
                  {type.price.toFixed(2)} Each
                </Typography>
              ))}
            </Box>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid ',
                borderColor: widgetBranding?.accent_colour || '',
                padding: '16px 24px 0 24px',
                justifyContent: 'right'
              }}
            >
              <Typography style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>
                Total
              </Typography>
              <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} ml={1}>
                ${totalPrice.toFixed(2)}
              </Typography>
            </Box>
          </Box>
          <Box style={{ padding: '24px 0 0' }}>
            <Button
              className="functionButton"
              onClick={isDisabled ? null : handleCreateBooking}
              variant="contained"
              disabled={isDisabled}
              style={{
                transition: '.3s',
                backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                color: widgetBranding.has_button_font_colour
                  ? widgetBranding.accent_colour || '#fff'
                  : '#fff',
                fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
              }}
            >
              Buy Tickets
            </Button>
          </Box>
        </>
      ) : (
        <Box style={{ padding: '24px 0 0', position: 'relative' }}>
          {!isAside ? (
            <>
              <EventTips
                style={{ padding: '16px' }}
                startTime={startDate}
                eventData={eventData}
                businessName={businessProfile.user.business_profile.business_name}
                address={businessProfile.user.business_profile.address}
                widgetBranding={widgetBranding}
              />
              <Box
                style={{ padding: '16px 0', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Box padding="0px 24px 16px 24px">
                  <Typography variant="body1" gutterBottom>
                    Order Summary
                  </Typography>
                  {types.map((type) => (
                    <Typography style={{ fontSize: '12px' }} key={type.id}>
                      {countByName[type.name]?.number ?? 0} x {type.name} Ticket $
                      {type.price.toFixed(2)} Each
                    </Typography>
                  ))}
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    borderTop: '1px solid',
                    borderColor: widgetBranding?.accent_colour || '',
                    padding: '16px 24px 0 24px',
                    justifyContent: 'right'
                  }}
                >
                  <Typography style={{ fontSize: '16px', fontWeight: 'bold', marginRight: '20px' }}>
                    Total
                  </Typography>
                  <Typography style={{ fontSize: '18px', fontWeight: 'bold' }} ml={1}>
                    ${totalPrice.toFixed(2)}
                  </Typography>
                </Box>
              </Box>
            </>
          ) : (
            <></>
          )}
          {eventEndDate < currentDate ? (
            <p style={{ fontWeight: 'bold' }}>This tickets are no longer for sale</p>
          ) : eventStartDate > currentDate ? (
            <p style={{ fontWeight: 'bold' }}>Tickets available soon</p>
          ) : (
            <Button
              className="functionButton pc-none"
              onClick={isDisabled ? null : () => openFilterDialog(countByName)}
              variant="contained"
              disabled={isDisabled}
              style={{
                transition: '.3s',
                backgroundColor: widgetBranding?.book_now_colour || '#485df9',
                color: widgetBranding.has_button_font_colour
                  ? widgetBranding.accent_colour || '#fff'
                  : '#fff',
                fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
              }}
            >
              Buy Tickets
            </Button>
          )}

          <Button
            className="functionButton sp-none"
            onClick={isDisabled ? null : handleCreateBooking}
            variant="contained"
            disabled={isDisabled}
            style={{
              transition: '.3s',
              backgroundColor: widgetBranding?.book_now_colour || '#485df9',
              color: widgetBranding.has_button_font_colour
                ? widgetBranding.accent_colour || '#fff'
                : '#fff',
              fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
            }}
          >
            Buy Tickets
          </Button>
        </Box>
      )}
      <MessageDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title="Invalid Ticket Request"
        description="The ticket request you submitted is not valid or contains errors. Please verify the information is correct and try submitting again."
      />
    </Container>
  );
}

export default TicketFilter;
