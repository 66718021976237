import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Box, Typography, Grid, Button } from '@material-ui/core';
import { LuGalleryHorizontal, LuLayoutGrid, LuList } from 'react-icons/lu';
import getEventLists from '../../hooks/@events/getEventLists';
import getBusinessProfile from '../../hooks/@common/getBusinessProfile';
import ImageSlider from '../../components/events/ImageSlider';
import iconWabi from '../../assets/images/icon-wabi.png';
import _ from 'lodash';

// CSS
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';

const SimilarEventsPopup = ({
  open,
  handleClose,
  eventType,
  eventId,
  businessName,
  widgetBranding
}) => {
  const { key } = useParams();
  const [eventLists, setEventLists] = useState([]);
  const [resetSlider, setResetSlider] = useState(false);
  const [displayClass, setDisplayClass] = useState('list');
  const [isStatus, setIsStatus] = useState(true);

  const DisplayIcon = ({ IconComponent, label, onClick }) => (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      <IconComponent
        style={{
          cursor: 'pointer',
          fontSize: '24px',
          color: widgetBranding?.accent_colour || '#fff'
        }}
        onClick={onClick}
      />
      <Typography
        variant="body1"
        style={{
          fontSize: '12px',
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%, 0%)',
          color: widgetBranding?.accent_colour || '#fff'
        }}
      >
        {label}
      </Typography>
    </div>
  );

  const changeDisplay = () => {
    setDisplayClass((prevClass) => {
      setResetSlider(true);
      let newClass;
      if (prevClass === 'portrait') {
        newClass = 'grid';
      } else if (prevClass === 'grid') {
        newClass = 'list';
      } else {
        newClass = 'portrait';
      }
      return newClass;
    });
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const [eventListsRes, businessProfileRes] = await Promise.all([
          getEventLists(key),
          getBusinessProfile(key)
        ]);
        const today = new Date();
        const filteredEvents = eventListsRes?.data.filter((event) => {
          const expirationDate = new Date(event?.data.expirationDate);
          const isExpired = today >= expirationDate;
          const isSameEvent = event?.data.id === eventId;
          const isMatchingType = event.type === eventType;
          const isStatusMatch = event?.data.status === 1;
          return !isExpired && !(isMatchingType && isSameEvent) && isStatusMatch;
        });
        setEventLists(filteredEvents);
        setIsStatus(businessProfileRes?.data.status === 1);
      } catch (error) {
        console.error(error);
      }
    }
    if (open) {
      fetchData();
    }
  }, [open, eventId, eventType, key]);

  const filteredStatusEvents = eventLists.filter((event) => event.data.status === 1);

  return (
    <>
      {filteredStatusEvents.length >= 1 && (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="similar-events-dialog-title"
          aria-describedby="similar-events-dialog-description"
          PaperProps={{
            style: {
              color: widgetBranding?.accent_colour || '#fff',
              backgroundColor: widgetBranding?.background_colour || '#000',
              borderRadius: '10px',
              width: '80%',
              maxWidth: '600px',
              minHeight: '100px',
              maxHeight: '70vh',
              position: 'absolute',
              top: '50%',
              padding: '8px 0',
              margin: '0 auto',
              overflow: 'auto',
              transform: 'translateY(-50%)'
            }
          }}
        >
          <Box style={{ position: 'relative' }}>
            <Box
              style={{
                position: 'sticky',
                top: '0',
                backgroundColor: widgetBranding?.background_colour || '#000',
                zIndex: '1000',
                padding: '0 16px'
              }}
            >
              <Grid
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '8px 0 16px'
                }}
              >
                <Grid item>
                  <img src={iconWabi} alt="Wabi Icon" style={{ width: '65px' }} />
                </Grid>
                <Grid item>
                  {displayClass === 'list' && (
                    <DisplayIcon IconComponent={LuList} label="List" onClick={changeDisplay} />
                  )}
                  {displayClass === 'grid' && (
                    <DisplayIcon
                      IconComponent={LuLayoutGrid}
                      label="Grid"
                      onClick={changeDisplay}
                    />
                  )}
                  {displayClass === 'portrait' && (
                    <DisplayIcon
                      IconComponent={LuGalleryHorizontal}
                      label="Portrait"
                      onClick={changeDisplay}
                    />
                  )}
                </Grid>
              </Grid>
              <Typography
                variant="body1"
                style={{
                  paddingBottom: '8px',
                  fontFamily: widgetBranding.font ? widgetBranding.font : 'inherit'
                }}
              >
                What's on at <span style={{ fontWeight: 'bold' }}>{businessName}</span>
              </Typography>
            </Box>
            <Box
              className={displayClass}
              style={{
                color: widgetBranding?.accent_colour || '#fff',
                position: 'relative',
                marginTop: '0',
                padding: '16px'
              }}
            >
              {!_.isEmpty(eventLists) && (
                <div className={displayClass}>
                  {eventLists.map((event) => (
                    <ImageSlider
                      key={event.id}
                      eventData={event}
                      resetSliderProp={resetSlider}
                      images={event.data.files.length > 0 ? event.data.files : widgetBranding.files}
                      token={key}
                      layout={displayClass}
                      widgetBranding={widgetBranding}
                    />
                  ))}
                </div>
              )}
            </Box>
            <Box
              style={{
                position: 'sticky',
                bottom: '0',
                backgroundColor: widgetBranding?.background_colour || '#000',
                zIndex: '1000',
                padding: '24px 0 16px',
                display: 'flex',
                justifyContent: 'center',
                marginTop: '8px'
              }}
            >
              <Button
                variant="contained"
                onClick={handleClose}
                style={{
                  color: widgetBranding.has_button_font_colour
                    ? widgetBranding.accentColour
                    : '#fff',
                  backgroundColor: widgetBranding?.book_now_colour || '#fff'
                }}
              >
                Skip for now
              </Button>
            </Box>
          </Box>
        </Dialog>
      )}
    </>
  );
};

export default SimilarEventsPopup;
