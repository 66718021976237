import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from './../../assets/images/logo.png';
const FooterWabi = () => {
  return (
    <Row>
      <Col xs lg="12" className="text-center">
        <p className="booking_widget__powered_by">
          Powered by{' '}
          <a href="https://javascriptpros.com" rel="noopener noreferrer" target="_blank">
            <img src={logo} alt="logo" />
          </a>
        </p>
      </Col>
    </Row>
  );
};

export default FooterWabi;
