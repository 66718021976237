import React from 'react';
import { IoMailSharp } from 'react-icons/io5';
import { MdLocalPhone } from 'react-icons/md';
import BusinessAddress from './businessAddress';

const ContactCard = ({ businessName, businessAddress, email, phone, description }) => {
  return (
    <div className="">
      <p className="wabi-header-second" style={{ marginBottom: '30px' }}>
        Contact us
      </p>
      {description && <p className="contact-description">{description}</p>}
      {businessAddress && businessName && (
        <BusinessAddress businessName={businessName} businessAddress={businessAddress} />
      )}
      {email && (
        <div className="detail-item">
          <IoMailSharp className="icon" />

          <a
            href={`mailto:${email}`}
            style={{ textDecoration: 'underline' }}
            className="wabi-subtitle"
          >
            {email}
          </a>
        </div>
      )}
      {phone && (
        <div className="detail-item">
          <MdLocalPhone className="icon" />
          <a
            href={`tel:${phone}`}
            style={{ textDecoration: 'underline' }}
            className="wabi-subtitle"
          >
            {phone}
          </a>
        </div>
      )}
    </div>
  );
};

export default ContactCard;
