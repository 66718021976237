import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './index.css';
import './assets/scss/tables.scss';
import Step1 from './components/step1';
import Step2 from './components/v2/step2';
import Step3 from './components/v2/step3';
import Booking from './pages/booking/index';
import Experience from './pages/booking/experience';
import Schedule from './pages/roster/schedule';
import AvailabilityRequest from './pages/roster/availability-request';
import Events from './pages/events/index';
import EventDetail from './pages/events/eventDetail';
import BookingDetails from './pages/booking/bookingDetails';
import EditBooking from './pages/booking/editBooking';
import EditBookingConfirm from './pages/booking/editBookingConfirm';

const App = (props) => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/roster/schedule" exact component={Schedule} />
        <Route path="/events/:key" exact component={Events} />
        <Route
          path="/:type(events|posts|tickets)/:id/:key"
          render={(props) => <EventDetail key={props.match.params.id} {...props} />}
        />
        <Route
          path="/:type(v2)/:key"
          render={(props) => <EventDetail key={props.match.params.id} {...props} />}
        />
        <Route path="/:key/:id/booking-details" exact component={BookingDetails} />
        <Route path="/:key/:id/edit-booking" exact component={EditBooking} />
        <Route path="/:key/:id/edit-booking-confirm" exact component={EditBookingConfirm} />
        <Route path="/old/:key" exact component={Step1} />
        <Route path="/step2/:booking_id/:key" exact component={Step2} />
        <Route path="/step3/:key" exact component={Step3} />
        <Route path="/:key" exact component={Booking} />
        <Route path="/event/:experience_id/:key" exact component={Experience} />
        <Route path="/roster/availability_request" exact component={AvailabilityRequest} />
      </Switch>
    </BrowserRouter>
  );
};
const targetContainer = document.getElementById('root');
ReactDOM.render(<App />, targetContainer);
