import React from 'react';
import { FaShop } from 'react-icons/fa6';

const BusinessAddress = ({ businessName, businessAddress }) => {
  return (
    <div className="wabi-title-wrapper">
      <FaShop />
      <p className="wabi-title">
        {businessName} <br />
        <span className="wabi-subtitle">{businessAddress}</span>
      </p>
    </div>
  );
};

export default BusinessAddress;
