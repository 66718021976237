import React, { useEffect } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { IoIosCheckmarkCircleOutline } from 'react-icons/io';

// Styles
import '../../assets/css/booking/bookingDetails.css';
// Components
import { Button } from '@material-ui/core';
import BookingCard from './bookingCard';
import ContactCard from './contactCard';
import FooterWabi from './footerWabi';
const EditBookingConfirm = () => {
  const history = useHistory();

  const { key, id } = useParams();
  const { state } = useLocation();
  const formData = {
    booking_date: state?.myBooking?.booking_date,
    first_name: state?.myBooking?.guest?.first_name,
    last_name: state?.myBooking?.guest?.last_name,
    partySize: state?.myBooking?.party_size,
    widgetBranding: state?.widgetBranding
  };
  const handleBack = () => {
    history.push(`/${key}/${id}/booking-details`);
  };

  window.addEventListener('popstate', function (event) {
    window.location.href = `/${key}/${id}/booking-details`;
  });
  const useScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  };

  useScrollToTop();
  return (
    <div className="wabi-root booking-management">
      <div className="wabi-container">
        <div className="check-step">
          <IoIosCheckmarkCircleOutline
            className="check-icon"
            style={{ color: '#4caf50', marginRight: '10px' }}
          />
          <h1 className="wabi-header-ttl">Booking Updated!</h1>
        </div>
        <div className="allWrapper">
          <p>This is the Updated Booking Confirmation page.</p>
          <div className="booking-card-wrapper">
            <BookingCard
              firstName={formData?.first_name}
              lastName={formData?.last_name}
              bookingDate={state?.myBooking?.booking_date}
              partySize={state?.myBooking?.party_size}
            />
          </div>
        </div>
        <Button
          variant="outlined"
          style={{
            backgroundColor: '#000',
            color: '#fff',
            borderColor: '#fff',
            borderRadius: '5px',
            padding: '10px 0',
            width: '100%',
            textTransform: 'capitalize',
            fontFamily: 'Inter',
            fontSize: '14px',
            textAlign: 'center',
            marginTop: '40px'
          }}
          onClick={handleBack}
        >
          <span>Booking Details</span>
        </Button>
        <div
          style={{
            borderTop: '1px solid',
            padding: '40px 20px',
            marginTop: '60px',
            marginBottom: '80px'
          }}
        >
          <ContactCard
            businessName={state?.businessName || ''}
            businessAddress={state?.businessAddress || ''}
            email={state?.myBooking?.owner.email || ''}
            phone={state?.myBooking?.owner.business_profile.phone_no || ''}
            description="If you have any questions or encounter any issues, please don’t hesitate to contact us."
          />
        </div>
      </div>
      <FooterWabi />
    </div>
  );
};

export default EditBookingConfirm;
