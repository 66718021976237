import axios from 'axios';
import { URL } from '../../config';

/**
 * Asynchronous function to create a booking ticket.
 * @param {string} key - The key used for authentication.
 * @param {Object} body - The body parameters for the ticket booking.
 * @param {number} body.ticket_id - The ID of the ticket.
 * @param {number} body.amount - The amount for the ticket.
 * @param {Array} body.ticket_type - The array of ticket types with their respective quantities.
 * @returns {Promise<Object>} - Promise containing the result of the booking ticket creation.
 * @throws {Error} - If an error occurs.
 */
const createBookingTicket = async (key, body) => {
  try {
    const response = await axios.post(`${URL}/widget/ticket/buy-ticket`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`
      }
    });

    const res = {
      data: response.data.data,
      status: response.status,
      error: null,
      isLoading: false
    };

    return res;
  } catch (error) {
    console.error('Error creating booking ticket:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false
    };

    return res;
  }
};

export default createBookingTicket;
