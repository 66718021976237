import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';

//material-ui
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Hidden from '@material-ui/core/Hidden';
import { Box, Typography, useTheme, useMediaQuery } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';

//components
import SelectComponent from '../../components/utils/SelectComponent';
import ExperienceModalList from '../../components/booking/ExperienceModalList';
import BookingGoogleMap from '../../components/booking/GoogleMap';

//hooks
import useBusinessProfile from '../../hooks/@booking/useBusinessProfile';
import useTimeslots from '../../hooks/@booking/useTimeslots';
import useAvailableTimeSlots from '../../hooks/@booking/useAvailableTimeSlots';
import { createBooking } from '../../hooks/@booking/useBooking';

//utils
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { formatDate } from 'react-day-picker/moment';
import { s3Bucket } from '../../config';

//styles
import useStyles from '../../styles/booking/styles';
import Loader from 'react-loader-spinner';
import BounceLoader from 'react-spinners/BounceLoader';

import { BsPeople } from 'react-icons/bs';

export default function Booking() {
  const { key } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const { businessProfileData, loading } = useBusinessProfile(key);
  const [showPrompt, setShowPrompt] = useState(false);
  const [promptMessage, setPromptMessage] = useState('');
  const {
    availableTimeSlot,
    loadAvailableTime,
    loadingChanges,
    setAvailableTimeSlot,
    displayNoResultFound,
    displayHasSpecialDay,
    displayHasNoShift
  } = useAvailableTimeSlots(key, 'widget');
  const availableTimeSlotRef = useRef(false);
  const {
    partySize,
    partySizes,
    experiences,
    businessName,
    businessAddress,
    businessPhoneNo,
    photo,
    widgetMessageTitle,
    widgetMessage,
    onlinePaymentFee,
    isOnlineEnabled
  } = businessProfileData;

  const [isExperienceOpen, setIsExperienceOpen] = React.useState(true);
  const [selectedPartySize, setSelectedPartySize] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [experienceId, setExperienceId] = React.useState(null);
  const [experiencePrice, setexperiencePrice] = React.useState(0);
  const [experienceName, setexperienceName] = React.useState(0);
  const { timeslots, selectedTime, setSelectedTime } = useTimeslots(
    selectedDate,
    experienceId,
    key,
    'widget'
  );
  const [daysOfWeek, setdaysOfWeek] = React.useState([]);

  useEffect(() => {
    if (partySize && partySizes.includes(partySize)) {
      setSelectedPartySize(partySize);
    }
  }, [partySize, partySizes]);

  useEffect(() => {
    if (selectedPartySize && selectedDate && selectedTime && !availableTimeSlotRef.current) {
      loadAvailableTime(selectedPartySize, selectedDate, selectedTime, experienceId);
      availableTimeSlotRef.current = true;
      setShowPrompt(false);
      setPromptMessage(null);
    }
  }, [
    selectedPartySize,
    selectedDate,
    selectedTime,
    loadAvailableTime,
    availableTimeSlot,
    experienceId
  ]);

  const filteredExperiences = experiences.filter(
    (experience) => experience.experience_connection !== null
  );
  const shouldOpenPopup = isExperienceOpen && filteredExperiences && filteredExperiences.length > 0;

  const handlePartySizeChange = (event) => {
    setSelectedPartySize(event.target.value);
    availableTimeSlotRef.current = false;
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedTime(null);
    setAvailableTimeSlot([]);
    setShowPrompt(false);
    setPromptMessage(null);
    availableTimeSlotRef.current = false;
  };

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
    setShowPrompt(false);
    setPromptMessage(null);
    availableTimeSlotRef.current = false;
  };

  const handleCloseExperience = () => {
    setIsExperienceOpen(false);
  };

  const shouldDisableDate = (date) => {
    if (experienceId !== null) {
      const isCorrectDayOfWeek = daysOfWeek[0].includes(date.getDay());
      return !isCorrectDayOfWeek;
    }
  };

  function getPartySizeText(size) {
    return size === 1 ? `${size} Person` : `${size} People`;
  }

  async function handleCreateBooking(shiftId, floorId, floorName, userId, timeLabel, time) {
    const data = {
      userId: userId,
      party_size: selectedPartySize,
      date: formatDate(selectedDate, 'YYYY-MM-DD'),
      time: time,
      booking_time: time + '',
      shift_id: shiftId,
      experience_id: experienceId,
      experience_name: experienceName,
      price: experiencePrice,
      widgetNoteTitle: widgetMessageTitle,
      widgetNoteMessage: widgetMessage,
      onlinePaymentFee: onlinePaymentFee,
      floor_id: floorId + ''
    };

    const bookingResponse = await createBooking(data, key, 'widget');

    if (bookingResponse.status !== 500) {
      const bookingData = {
        date: formatDate(selectedDate, 'dddd, D MMM'),
        time: timeLabel,
        partySize: bookingResponse.data.party_size,
        floor_name: floorName,
        createdAt: bookingResponse.data.created_at,
        shift_id: bookingResponse.data.shift_id,
        experience_id: experienceId,
        experience_name: experienceName,
        price: experiencePrice,
        widgetNoteTitle: widgetMessageTitle,
        widgetNoteMessage: widgetMessage,
        onlinePaymentFee: onlinePaymentFee,
        businessName: businessName,
        api: 'widget'
      };
      const newUrl = `/step2/${bookingResponse.data.id}/${key}`;

      history.push({
        pathname: newUrl,
        bookingData: bookingData
      });
    } else {
      setPromptMessage(bookingResponse.error);
      setShowPrompt(true);
    }
  }

  const handleSelecExperience = (
    experienceId,
    experienceName,
    tickets,
    price,
    dayOfWeek,
    startDate,
    endDate,
    experienceDescription,
    experiencePhoto,
    sold,
    shiftName,
    shiftUntilTime,
    shiftTimeRange
  ) => {
    const data = {
      experienceId: experienceId,
      experienceName: experienceName,
      tickets: tickets,
      price: price,
      dayOfWeek: dayOfWeek,
      startDate: startDate,
      endDate: endDate,
      experienceDescription: experienceDescription,
      experiencePhoto: experiencePhoto,
      businessAddress: businessAddress,
      businessName: businessName,
      businessPhoneNo: businessPhoneNo,
      widgetMessageTitle: widgetMessageTitle,
      widgetMessage: widgetMessage,
      partySizes: partySizes,
      sold: sold,
      onlinePaymentFee: onlinePaymentFee,
      shiftName: shiftName,
      shiftUntilTime: shiftUntilTime,
      shiftTimeRange: shiftTimeRange,
      isOnlineEnabled: isOnlineEnabled
    };

    const url = `/event/${experienceId}/${key}`;
    history.push({
      pathname: url,
      state: data
    });
  };

  const theme = useTheme();
  const isXS = useMediaQuery(theme.breakpoints.down('xs'));
  const [filteredTimeSlots, setfilteredTimeSlots] = useState([]);
  function convertTimeToSeconds(timeString) {
    const match = timeString.match(/(\d{1,2}):(\d{1,2})([APMapm]{2})/);

    if (!match) {
      return null;
    }

    const [hours, minutes, meridian] = match.slice(1, 4);
    let hoursIn24Format = parseInt(hours, 10);

    if (isNaN(hoursIn24Format) || isNaN(parseInt(minutes, 10))) {
      return null;
    }

    if (meridian.toLowerCase() === 'pm' && hoursIn24Format !== 12) {
      hoursIn24Format += 12;
    } else if (meridian.toLowerCase() === 'am' && hoursIn24Format === 12) {
      hoursIn24Format = 0;
    }

    const totalSeconds = hoursIn24Format * 3600 + parseInt(minutes, 10) * 60;
    return totalSeconds;
  }

  function getCurrentTimeFormatted() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const meridian = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    const formattedTime = `${formattedHours}:${minutes.toString().padStart(2, '0')}${meridian}`;
    return convertTimeToSeconds(formattedTime);
  }

  useEffect(() => {
    if (timeslots && timeslots.length > 0) {
      const formattedSelectedDate = formatDate(selectedDate, 'YYYY-MM-DD');
      const formattedCurrentDate = formatDate(new Date(), 'YYYY-MM-DD');
      const currentTime = getCurrentTimeFormatted();
      if (formattedSelectedDate === formattedCurrentDate) {
        const resTimeslots = timeslots.filter((timeslot) => timeslot.value >= currentTime);
        setfilteredTimeSlots(resTimeslots);
        if (resTimeslots.length > 0) {
          setSelectedTime(resTimeslots[0].value);
        } else {
          setSelectedTime([]);
        }
      } else {
        setfilteredTimeSlots(timeslots);
        setSelectedTime(timeslots[0].value);
      }
    }
  }, [timeslots]);
  return (
    <Container maxWidth="lg" className={classes.root}>
      {loading ? (
        <Grid
          container
          className={classes.fullWidthGrid}
          style={{
            minHeight: '50vh',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Loader type="Oval" color="#FD3366" height={30} width={30} />
          <Typography variant="h5" style={{ color: '#FD6C57', marginLeft: '3px' }} gutterBottom>
            Loading ...
          </Typography>
        </Grid>
      ) : (
        <Grid container className={classes.fullWidthGrid}>
          <Hidden only={['xl', 'lg']}>
            <Grid item xs={12} md={12}>
              <Paper className={classes.imagePaper} elevation={3}>
                <img
                  src={s3Bucket + photo}
                  alt={businessName + '1'}
                  onError={(e) => {
                    e.target.src =
                      'https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg';
                  }}
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                    height: '400px',
                    objectFit: 'cover'
                  }}
                />
              </Paper>
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={12} xl={8} lg={8}>
            <Box className={classes.paper}>
              <Typography variant="h2" style={{ fontWeight: 'bold' }} gutterBottom>
                {businessName}
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                {businessAddress}
              </Typography>
              <Typography variant="subtitle1" display="block" gutterBottom>
                {businessPhoneNo}
              </Typography>
              {isOnlineEnabled ? (
                <>
                  <Paper className={classes.selectPaper} elevation={3}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <SelectComponent
                          value={selectedPartySize || ''}
                          onChange={handlePartySizeChange}
                          options={partySizes.map((size) => ({
                            value: size,
                            text: getPartySizeText(size)
                          }))}
                          className={classes.select}
                          icon="people"
                          color="#000"
                        />
                      </Grid>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid item xs={12} sm={4}>
                          <KeyboardDatePicker
                            disableToolbar
                            variant="inline"
                            format="d MMM yyyy"
                            minDate={new Date()}
                            maxDate={new Date('5000-01-01')}
                            id="date-picker-inline"
                            value={selectedDate}
                            onChange={handleDateChange}
                            shouldDisableDate={shouldDisableDate}
                            autoOk={true}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            InputProps={{ readOnly: true }}
                            style={{ width: '100%' }}
                          />
                        </Grid>
                      </MuiPickersUtilsProvider>
                      <Grid item xs={12} sm={4}>
                        <SelectComponent
                          value={selectedTime}
                          onChange={handleTimeChange}
                          options={
                            filteredTimeSlots
                              ? [...new Set(filteredTimeSlots.map((slot) => slot.value))].map(
                                  (value) => ({
                                    value,
                                    text: filteredTimeSlots.find((slot) => slot.value === value)
                                      .label
                                  })
                                )
                              : []
                          }
                          className={classes.select}
                          icon="time"
                          color="#000"
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                  <Box marginTop={1} style={{ textAlign: 'justify' }}>
                    {loadingChanges ? (
                      <Grid
                        container
                        className={classes.fullWidthGrid}
                        style={{
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                      >
                        <div className="sweet-loading">
                          <BounceLoader
                            css={classes.override}
                            size={30}
                            color={'#FE7D86'}
                            loading={loadingChanges}
                          />
                        </div>
                      </Grid>
                    ) : (
                      <div>
                        {displayHasNoShift && displayNoResultFound ? (
                          <Typography variant="subtitle1">
                            Sorry there are currently no tables available at this time. Please
                            select a different date or time
                          </Typography>
                        ) : displayHasSpecialDay && displayNoResultFound ? (
                          <Typography variant="subtitle1">
                            Sorry we are closed on this date. Please select a different date
                          </Typography>
                        ) : displayNoResultFound ? (
                          <Typography variant="subtitle1">
                            Sorry there are currently no tables available at this time. Please
                            select a different date or time
                          </Typography>
                        ) : null}

                        {showPrompt && <Typography>{promptMessage}</Typography>}
                        <Grid container justify={isXS ? 'space-evenly' : ''}>
                          {filteredTimeSlots.length === 0
                            ? null
                            : availableTimeSlot &&
                              selectedDate &&
                              selectedTime &&
                              filteredTimeSlots.length > 0 &&
                              availableTimeSlot.map((timeslot) =>
                                timeslot.available
                                  ? timeslot.floors.map((floor, floorIndex) => (
                                      <Grid item key={floorIndex}>
                                        <div
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'stretch'
                                          }}
                                        >
                                          <Button
                                            onClick={() =>
                                              handleCreateBooking(
                                                timeslot.shift_id,
                                                floor.id,
                                                floor.floor_name,
                                                floor.user_id,
                                                timeslot.label,
                                                timeslot.value
                                              )
                                            }
                                            key={floor.id}
                                            variant="contained"
                                            className={classes.button}
                                          >
                                            <Box
                                              style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                                width: '100%'
                                              }}
                                            >
                                              <div
                                                style={{
                                                  fontWeight: 'bold',
                                                  width: '100%',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap', // add this line
                                                  fontSize: '14px'
                                                }}
                                              >
                                                {timeslot.label}
                                              </div>
                                              <div
                                                style={{
                                                  width: '100%',
                                                  overflowWrap: 'break-word',
                                                  fontSize: '9px'
                                                }}
                                              >
                                                {floor.floor_name}
                                              </div>
                                            </Box>
                                          </Button>
                                        </div>
                                      </Grid>
                                    ))
                                  : null
                              )}
                        </Grid>
                      </div>
                    )}
                  </Box>
                </>
              ) : (
                <Typography style={{ fontStyle: 'italic' }}>
                  Online booking for this restaurant is not currently available. Please try to call
                  the restaurant to book your reservation.
                </Typography>
              )}
              <BookingGoogleMap businessName={businessName} businessAddress={businessAddress} />
            </Box>
          </Grid>
          <Hidden only={['xs', 'md', 'sm']}>
            <Grid item xs={12} sm={6} md={12} xl={4} lg={4} style={{ paddingLeft: '50px' }}>
              <Paper className={classes.imagePaper} elevation={3}>
                <img
                  src={s3Bucket + photo}
                  alt={businessName}
                  onError={(e) => {
                    e.target.src =
                      'https://www.survivorsuk.org/wp-content/uploads/2017/01/no-image.jpg';
                  }}
                  style={{
                    borderRadius: '20px',
                    width: '100%',
                    height: '30vh',
                    objectFit: 'cover'
                  }}
                />
              </Paper>
            </Grid>
          </Hidden>
        </Grid>
      )}

      {shouldOpenPopup && (
        <ExperienceModalList
          open={shouldOpenPopup}
          onClose={shouldOpenPopup}
          experiences={filteredExperiences}
          handleCloseExperience={handleCloseExperience}
          handleSelectExperience={handleSelecExperience}
          businessName={businessName}
        />
      )}
    </Container>
  );
}
