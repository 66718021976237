import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import GoogleMap from '../utils/GoogleMap';

const BookingGoogleMap = ({ businessName, businessAddress }) => {
  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        style={{
          padding: '10px',
          padding: '20px',
          fontWeight: 'bolder'
        }}
      >
        More information
      </Typography>
      <Grid container style={{ marginLeft: '10px' }}>
        <GoogleMap businessName={businessName} businessAddress={businessAddress} />
      </Grid>
    </>
  );
};

export default BookingGoogleMap;
