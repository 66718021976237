import axios from 'axios';
import { URL } from '../../config';

export const updateMyBooking = async (params, id, key) => {
  try {
    const response = await axios.post(
      `${URL}/widget/v2/booking/${id}/update`,
      { ...params }, // Include isChecking flag in the request body
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${key}`
        }
      }
    );

    // Format the response object
    return {
      data: response.data.data,
      status: response.status,
      error: null,
      isLoading: false
    };
  } catch (error) {
    console.error('Error updating booking:', error);

    // Handle error response
    return {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false
    };
  }
};
