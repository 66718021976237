import axios from 'axios';
import { URL } from '../../config';

export const UseSearchableAction = async (key, params) => {
  try {
    const response = await axios.post(`${URL}/widget/entity/searchable-action`, params, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`
      }
    });

    const res = {
      data: response.data,
      status: response.status_code,
      error: null
    };

    return res;
  } catch (error) {
    console.error('Error creating booking:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error'
    };

    return res;
  }
};
