// TicketSelection.js
import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import { FiPlus, FiMinus } from 'react-icons/fi';
import '../../assets/scss/events/eventsDisplay.scss';
import '../../assets/scss/events/detail.scss';

const TicketSelection = ({
  typeId,
  name,
  price,
  limit,
  propTicketCountByName,
  onCountChange,
  isAside = false,
  widgetBranding
}) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    if (propTicketCountByName && propTicketCountByName[name]) {
      setCount(propTicketCountByName[name].number);
    }
  }, [propTicketCountByName, name]);

  const handleCount = (num) => () => {
    const newCount = count + num;
    if (newCount > limit || newCount < 0) return;
    const param = {
      number: newCount,
      price: price,
      id: typeId
    };
    onCountChange(name, param);
    setCount(newCount);
  };

  return (
    <Box
      style={{
        display: 'flex',
        borderBottom: '1px solid',
        borderColor: widgetBranding?.accent_colour || '',

        alignItems: 'center',
        justifyContent: 'space-between'
      }}
      padding={isAside ? '8px' : '16px 24px'}
    >
      <Grid style={{ flexGrow: '1' }}>
        <Typography variant="body1">{name} Ticket</Typography>
        <Typography style={{ fontSize: '12px', marginTop: '8px' }}>${price} Each</Typography>
      </Grid>
      <Grid style={{ display: 'flex', gap: '16px' }}>
        <Button
          variant="text"
          style={{
            color: widgetBranding?.accent_colour || '#fff',
            padding: '0',
            minWidth: 'auto'
          }}
          onClick={handleCount(-1)}
        >
          <FiMinus style={{ fontSize: '20px' }} />
        </Button>
        <Typography variant="body1" style={{ fontSize: '14px' }}>
          {count}
        </Typography>
        <Button
          variant="text"
          style={{ color: widgetBranding?.accent_colour || '#fff', padding: '0', minWidth: 'auto' }}
          onClick={handleCount(1)}
        >
          <FiPlus style={{ fontSize: '20px' }} />
        </Button>
      </Grid>
    </Box>
  );
};
export default TicketSelection;
