import axios from 'axios';
import { URL } from '../../config';

/**
 * Asynchronous function to retrieve widget branding.
 * @param {string} key - The key used for authentication.
 * @returns {Promise<Object>} - Promise containing the result of event list retrieval.
 * @throws {Error} - If an error occurs.
 */

const getWidgetBranding = async (key) => {
  try {
    const response = await axios.get(`${URL}/widget/widget-branding`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`
      }
    });

    const res = {
      data: response.data.data,
      status: response.status,
      error: null,
      isLoading: false
    };

    return res;
  } catch (error) {
    console.error('Error get widget branding:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false
    };

    return res;
  }
};

export default getWidgetBranding;
