import axios from 'axios';
import { URL } from '../../config';

/**
 * Asynchronous function to retrieve event lists with pagination.
 * @param {string} key - The key used for authentication.
 * @param {number} [page=1] - The page number to fetch (default is 1).
 * @returns {Promise<Object>} - Promise containing the result of event list retrieval.
 * @throws {Error} - If an error occurs.
 */
const getEventLists = async (key, page = 1) => {
  try {
    const response = await axios.get(`${URL}/widget/entity/search`, {
      params: {
        sort: 'createdAt',
        direction: 'desc',
        page: page
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`
      }
    });

    const res = {
      data: response.data.data,
      status: response.status,
      error: null,
      isLoading: false
    };

    // Check if there are more pages to fetch
    const { currentPage, lastPage } = response.data;
    if (currentPage < lastPage) {
      // Fetch next page recursively
      const nextPageData = await getEventLists(key, currentPage + 1);
      res.data = [...res.data, ...nextPageData.data]; // Merge current and next page data
    }

    return res;
  } catch (error) {
    console.error('Error fetching event lists:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false
    };

    return res;
  }
};

export default getEventLists;
