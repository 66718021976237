import axios from 'axios';
import { URL } from '../../config';

export const cancelBooking = async (id, key) => {
  try {
    const response = await axios.post(`${URL}/widget/v2/booking/${id}/cancel`, '', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${key}`,
      },
    });

    const res = {
      data: response.data.data.booking,
      status: response.status,
      error: null,
      isLoading: false,
    };

    return res;
  } catch (error) {
    console.error('Error cancelling booking:', error);

    const res = {
      data: null,
      status: error.response ? error.response.status : 500,
      error: error.response ? error.response.data.message : 'Internal Server Error',
      isLoading: false,
    };

    return res;
  }
};