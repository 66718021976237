import React from 'react';
import { useMediaQuery } from '@mui/material';
import TruncateTypography from '../common/TruncateTypography';

const EventDescription = ({ isExperience, eventData, widgetBranding }) => {
  const isMobile = useMediaQuery('(max-width:768px)');
  const maxChars = isMobile ? 60 : 280;

  return (
    <>
      {isExperience ? (
        <TruncateTypography
          text={eventData.data.exp_description}
          maxChars={maxChars}
          widgetBranding={widgetBranding}
        />
      ) : (
        <TruncateTypography
          text={eventData.data.description}
          maxChars={maxChars}
          widgetBranding={widgetBranding}
        />
      )}
    </>
  );
};

export default EventDescription;
